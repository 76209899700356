import React, { useState, useEffect } from "react"
import {
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
} from "reactstrap"
//Import Breadcrumb
import { Card, ModalBody, Label, Input, Form, Table } from "reactstrap"

import { Link, useNavigate, useParams } from "react-router-dom"
import { CardBody } from "reactstrap"
import classnames from "classnames"
import axios from "axios"
import moment from "moment/moment"
import Loader from "pages/Loader"
import swal from "sweetalert"
import { Config, getDecryptedData, getEncryptedData } from "pages/Config"

const DietPlan = props => {
  const [verticalActiveTab, setverticalActiveTab] = useState(1)
  const [verticalActiveTab2, setverticalActiveTab2] = useState(1)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(false)
  const params = useParams()

  // ===================================================================================
  const [all_week_days, setAllWeekDays] = useState([])
  const [week_list, setWeekList] = useState(0)
  const [loading, setLoading] = useState(false)

  const [viewPlanModal, setViewPlanModal] = useState(false)

  // for form repeater
  const [wakingRows, setWakingRows] = useState([{ id: 1 }])
  const [breakfastRows, setBreakfastRows] = useState([{ id: 1 }])
  const [mealRows, setMealRows] = useState([{ id: 1 }])
  const [lunchRows, setLunchRows] = useState([{ id: 1 }])
  const [snackRows, setSnackRows] = useState([{ id: 1 }])
  const [DinnerRows, setDinnerRows] = useState([{ id: 1 }])
  const [postDinnerRows, setPostDinnerRows] = useState([{ id: 1 }])

  const [wakingUpFoods, setWakingUpFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [breakfastFoods, setBreakfastFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [midMealFoods, setMidMealFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [lunchFoods, setLunchFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [eveningSnackFoods, setEveningSnackFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [dinnerFoods, setDinnerFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])
  const [postDinnerFoods, setPostDinnerFoods] = useState([
    { time: "", desc: "", isDone: 0, id: 1 },
  ])

  const [planDate, setPlanDate] = useState("")
  const [planNote, setPlanNote] = useState("")
  const [plan_id, setPlanId] = useState("")

  const [activePlans, setActivePlans] = useState([])
  const [prevPlan, setPrevPlan] = useState("")
  const [loading2, setLoading2] = useState(false)

  const navigate = useNavigate()

  // GET ACTIVE PLANS
  useEffect(() => {
    const getActivePlans = async () => {
      try {
        const formData = new FormData()
        formData.append("client_id", props.client_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        const response = await axios.post(
          `${Config.apiUrl}/client_dietplan`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        // console.log("Diet plans", response.data)
        const newPlans = response.data.filter(plan => plan.status === 1)

        setActivePlans(newPlans)
      } catch (error) {
        console.log(error)
      }
    }
    getActivePlans()
  }, [props.client_id, all_week_days])

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  const toggleVertical2 = tab => {
    if (verticalActiveTab2 !== tab) {
      setverticalActiveTab2(tab)
    }
  }

  // form repeater
  const onAddRow = (rows, setRows, setFoods) => {
    const modifiedRows = [...rows]
    modifiedRows.push({ id: modifiedRows.length + 1 })
    setRows(modifiedRows)

    setFoods(foods => [
      ...foods,
      { time: "", desc: "", isDone: 0, id: foods[foods.length - 1].id + 1 },
    ])
  }

  const onDeleteRow = (id, rows, setRows, setFoods, index) => {
    var modifiedRows = [...rows]
    modifiedRows = modifiedRows.filter(x => x["id"] !== id)
    setRows(modifiedRows)

    setFoods(foods => {
      const updatedForms = [...foods]
      const newdata = updatedForms.filter((serv, ind) => ind !== index)

      return newdata.map((data, i) => ({ ...data, id: i + 1 }))
    })
  }

  // getting all diet plan
  const getDietPlan = async () => {
    setLoading(true)

    try {
      const formData = new FormData()
      formData.append("client_id", props.client_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/client_dietplan`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      // console.log(response.data)
      // dividing whole array by 7
      const newArray = Array.from(
        { length: Math.ceil(response.data.length / 7) },
        (_, index) => response.data.slice(index * 7, (index + 1) * 7)
      )

      setAllWeekDays(newArray)
      setWeekList(newArray.length)

      setLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (props.verticalActiveTab == 2) {
      getDietPlan()
    }
  }, [props.verticalActiveTab])

  /**
   * Handling the modal state
   */
  const toggle = () => {
    if (modal) {
      setModal(false)
    } else {
      setModal(true)
    }
    setIsEdit(false)
  }

  // particular date diet plan
  const fetchDietPlan = (plan_date, edit) => {
    const formData = new FormData()
    formData.append("client_id", props.client_id)
    formData.append("date", plan_date)
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${Config.apiUrl}/view_dietplan`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setWakingUpFoods(
          JSON.parse(getDecryptedData(response.data.data[0].on_waking_up))
        )
        setBreakfastFoods(
          JSON.parse(getDecryptedData(response.data.data[0].breakfast))
        )
        setMidMealFoods(
          JSON.parse(getDecryptedData(response.data.data[0].mid_meal))
        )
        setLunchFoods(JSON.parse(getDecryptedData(response.data.data[0].lunch)))
        setEveningSnackFoods(
          JSON.parse(getDecryptedData(response.data.data[0].evening_snack))
        )
        setDinnerFoods(
          JSON.parse(getDecryptedData(response.data.data[0].dinner))
        )
        setPostDinnerFoods(
          JSON.parse(getDecryptedData(response.data.data[0].post_dinner))
        )
        setPlanNote(getDecryptedData(response.data.data[0].note))
        setPlanId(response.data.data[0].id)

        let tempRows = []
        JSON.parse(
          getDecryptedData(response.data.data[0].on_waking_up)
        ).forEach((data, index) => {
          tempRows.push({ id: index + 1 })
        })
        setWakingRows(tempRows)

        tempRows = []
        JSON.parse(getDecryptedData(response.data.data[0].breakfast)).forEach(
          (data, index) => {
            tempRows.push({ id: index + 1 })
          }
        )
        setBreakfastRows(tempRows)

        tempRows = []
        JSON.parse(getDecryptedData(response.data.data[0].mid_meal)).forEach(
          (data, index) => {
            tempRows.push({ id: index + 1 })
          }
        )
        setMealRows(tempRows)

        tempRows = []
        JSON.parse(getDecryptedData(response.data.data[0].lunch)).forEach(
          (data, index) => {
            tempRows.push({ id: index + 1 })
          }
        )
        setLunchRows(tempRows)

        tempRows = []
        JSON.parse(
          getDecryptedData(response.data.data[0].evening_snack)
        ).forEach((data, index) => {
          tempRows.push({ id: index + 1 })
        })
        setSnackRows(tempRows)

        tempRows = []
        JSON.parse(getDecryptedData(response.data.data[0].dinner)).forEach(
          (data, index) => {
            tempRows.push({ id: index + 1 })
          }
        )
        setDinnerRows(tempRows)

        tempRows = []
        JSON.parse(getDecryptedData(response.data.data[0].post_dinner)).forEach(
          (data, index) => {
            tempRows.push({ id: index + 1 })
          }
        )
        setPostDinnerRows(tempRows)

        if (!edit) setViewPlanModal(true)
      })
      .catch(err => console.log(err))
  }

  // changing food details accordian
  const handleChangeFoodData = (index, setFood, value, keyname) => {
    setFood(foods => {
      let oldFood = [...foods]

      oldFood[index][keyname] = value
      return oldFood
    })
  }

  // handle save dietplan
  const handleSaveDietPlan = async e => {
    e.preventDefault()

    try {
      const formData = new FormData()
      formData.append(
        "on_waking_up",
        getEncryptedData(JSON.stringify(wakingUpFoods))
      )
      formData.append(
        "breakfast",
        getEncryptedData(JSON.stringify(breakfastFoods))
      )
      formData.append(
        "mid_meal",
        getEncryptedData(JSON.stringify(midMealFoods))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunchFoods)))
      formData.append("dinner", getEncryptedData(JSON.stringify(dinnerFoods)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(eveningSnackFoods))
      )
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(postDinnerFoods))
      )
      formData.append("client_id", props.client_id)
      formData.append("date", planDate)
      formData.append("note", getEncryptedData(planNote))
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/add_dietplan`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log(response)
      if (response.data.data == "already added") {
        swal("Plan Already added on this date")
      } else {
        setModal(false)
        const newArray = Array.from(
          { length: Math.ceil(response.data.length / 7) },
          (_, index) => response.data.slice(index * 7, (index + 1) * 7)
        )

        setAllWeekDays(newArray)

        swal("", "Diet Plan added successfully", "success").then(() => {
          setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
          setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

          setWakingRows([{ id: 1 }])
          setBreakfastRows([{ id: 1 }])
          setMealRows([{ id: 1 }])
          setLunchRows([{ id: 1 }])
          setSnackRows([{ id: 1 }])
          setDinnerRows([{ id: 1 }])
          setPostDinnerRows([{ id: 1 }])

          setPlanDate("")
          setPlanNote("")
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  // Edit Diet Plan =====================================================
  const handleEditDietPlan = async () => {
    try {
      const formData = new FormData()
      formData.append(
        "on_waking_up",
        getEncryptedData(JSON.stringify(wakingUpFoods))
      )
      formData.append(
        "breakfast",
        getEncryptedData(JSON.stringify(breakfastFoods))
      )
      formData.append(
        "mid_meal",
        getEncryptedData(JSON.stringify(midMealFoods))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunchFoods)))
      formData.append("dinner", getEncryptedData(JSON.stringify(dinnerFoods)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(eveningSnackFoods))
      )
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(postDinnerFoods))
      )
      formData.append("client_id", props.client_id)
      formData.append("date", planDate)
      formData.append("note", getEncryptedData(planNote))
      formData.append("plan_id", plan_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/edit_dietplan`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log(response.data)

      setModal(false)

      swal("", "Diet Plan Updated successfully", "success").then(() => {
        setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
        setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

        setWakingRows([{ id: 1 }])
        setBreakfastRows([{ id: 1 }])
        setMealRows([{ id: 1 }])
        setLunchRows([{ id: 1 }])
        setSnackRows([{ id: 1 }])
        setDinnerRows([{ id: 1 }])
        setPostDinnerRows([{ id: 1 }])
        setPlanDate("")
        setPlanNote("")
        setPlanId("")
      })
    } catch (error) {
      console.log(error)
    }
  }

  // Delete Plan Logic
  const onDeletePlan = () => {
    swal({
      title: "Are you sure?",
      text: "do you really want to delete this Diet Plan",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("plan_id", plan_id)
        formData.append("client_id", props.client_id)
        formData.append("added_by", localStorage.getItem("adminId"))
        formData.append("date", planDate)

        axios
          .post(`${Config.apiUrl}/delete_dietplan`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            // console.log(response)

            const newArray = Array.from(
              { length: Math.ceil(response.data.data.length / 7) },
              (_, index) => response.data.data.slice(index * 7, (index + 1) * 7)
            )

            setAllWeekDays(newArray)

            swal("Diet Plan has been deleted successfully!", {
              icon: "success",
            }).then(() => {
              setWakingUpFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setBreakfastFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setMidMealFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setLunchFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setEveningSnackFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])
              setPostDinnerFoods([{ time: "", desc: "", isDone: 0, id: 1 }])

              setWakingRows([{ id: 1 }])
              setBreakfastRows([{ id: 1 }])
              setMealRows([{ id: 1 }])
              setLunchRows([{ id: 1 }])
              setSnackRows([{ id: 1 }])
              setDinnerRows([{ id: 1 }])
              setPostDinnerRows([{ id: 1 }])

              setPlanDate("")
              setPlanNote("")
              setPlanId("")
              toggle()
            })
          })
      }
    })
  }

  // get plan copy from previous plans
  const fetchDietPlan2 = () => {
    if (prevPlan != "" && prevPlan != "Copy form") {
      setLoading2(true)
      const formData = new FormData()
      formData.append("client_id", props.client_id)
      formData.append("date", prevPlan)

      axios
        .post(`${Config.apiUrl}/view_dietplan`, formData, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          let tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].on_waking_up)
          )
          setWakingUpFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].breakfast)
          )
          setBreakfastFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].mid_meal)
          )
          setMidMealFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(getDecryptedData(response.data.data[0].lunch))
          setLunchFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].evening_snack)
          )
          setEveningSnackFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(getDecryptedData(response.data.data[0].dinner))
          setDinnerFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))

          tempFoods = JSON.parse(
            getDecryptedData(response.data.data[0].post_dinner)
          )
          setPostDinnerFoods(tempFoods.map(food => ({ ...food, isDone: 0 })))
          setPlanNote(getDecryptedData(response.data.data[0].note))

          let tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].on_waking_up)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setWakingRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].breakfast)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setBreakfastRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].mid_meal)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setMealRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].lunch)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setLunchRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].evening_snack)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setSnackRows(tempRows)

          tempRows = []
          JSON.parse(getDecryptedData(response.data.data[0].dinner)).forEach(
            (data, index) => {
              tempRows.push({ id: index + 1 })
            }
          )
          setDinnerRows(tempRows)

          tempRows = []
          JSON.parse(
            getDecryptedData(response.data.data[0].post_dinner)
          ).forEach((data, index) => {
            tempRows.push({ id: index + 1 })
          })
          setPostDinnerRows(tempRows)

          setLoading2(false)
        })
        .catch(err => {
          setLoading2(false)
          console.log(err)
        })
    } else {
      swal("Please Select Date")
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}

      <Row className="mx-0">
        <Card>
          <CardBody className="pt-4">
            <Row>
              <Col md="3" className="px-0">
                <Nav
                  pills
                  className={`side-tab flex-column px-2 py-3 diet-plan-tab`}
                >
                  {[...Array(week_list)].map((_, key) => {
                    return (
                      <NavItem key={key}>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            "mb-2": true,
                            active: verticalActiveTab == key + 1,
                          })}
                          onClick={() => {
                            toggleVertical(key + 1)
                          }}
                        >
                          Week {key + 1}
                        </NavLink>
                      </NavItem>
                    )
                  })}
                </Nav>
              </Col>
              <Col md="9 pe-0">
                <div className=" bg-transparent mb-0">
                  <TabContent
                    activeTab={verticalActiveTab}
                    className="text-muted mt-4 mt-md-0"
                  >
                    {all_week_days.map((week_data, key) => {
                      return (
                        <TabPane key={key} tabId={key + 1}>
                          <div className="ps-md-4">
                            <table className="table table-bordered text-center responsive-table responsive ncp-table">
                              <thead className="table-green">
                                <tr>
                                  <th>Date</th>
                                  <th>Day</th>
                                  <th>Status</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {week_data.map((week, i) => {
                                  return (
                                    <tr key={i}>
                                      <td data-label="Date">
                                        {moment(week.date).format("DD-MM-YYYY")}
                                      </td>
                                      <td data-label="Day">{week.day}</td>
                                      <td data-label="Status">
                                        <div
                                          className={`badge bg-soft ${
                                            week.status === 1
                                              ? "bg-success text-success"
                                              : "bg-danger text-danger"
                                          } `}
                                          style={{ fontSize: "12px" }}
                                        >
                                          {week.status === 1
                                            ? "Plan Created"
                                            : "Plan Not Created"}
                                        </div>
                                      </td>
                                      <td data-label='Action'>
                                        {week.status === 1 ? (
                                          <>
                                           
                                            <Link
                                              to={`${Config.appUrl}/add-diet-plan/${params?.client_id}`}
                                              className="btn btn-sm btn-outline-secondary ms-2"
                                            >
                                              <i className="fas fa-pen"></i>{" "}
                                              Edit
                                            </Link>
                                          </>
                                        ) : (
                                          <Link
                                            to={`${Config.appUrl}/add-diet-plan/${params?.client_id}`}
                                            className="btn btn-sm btn-purple btn-green"
                                          >
                                            <i className="fas fa-plus me-1"></i>
                                            Add Diet Plan
                                          </Link>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </table>
                          </div>
                        </TabPane>
                      )
                    })}
                  </TabContent>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Row>

      {/* view Modal */}
      <Modal
        isOpen={viewPlanModal}
        className="bd-custom-md-3 modal-fullscreen"
        centered
      >
        <div className="modal-header py-3 px-4 border-bottom-0 bg-light-purple">
          <h5 className="modal-title mt-0 text-purple">View Diet Plan</h5>
          <button
            type="button"
            onClick={() => {
              setViewPlanModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <div className="px-4">
            <Row className="mt-4 px-4">
              {/* ========================================== */}

              <div className="col-md-3">
                <Nav
                  pills
                  className={`side-tab flex-column px-2 py-3 diet-plan-tab`}
                >
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab2 === 1,
                      })}
                      onClick={() => {
                        toggleVertical2(1)
                      }}
                    >
                      Morning Diet Plan
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab2 === 2,
                      })}
                      onClick={() => {
                        toggleVertical2(2)
                      }}
                    >
                      Afternoon Diet Plan
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab2 === 3,
                      })}
                      onClick={() => {
                        toggleVertical2(3)
                      }}
                    >
                      Evening Diet Plan
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        "mb-2": true,
                        active: verticalActiveTab2 === 4,
                      })}
                      onClick={() => {
                        toggleVertical2(4)
                      }}
                    >
                      View Notes
                    </NavLink>
                  </NavItem>
                </Nav>
              </div>
              <div className="col-md-9 ps-md-4">
                <TabContent
                  activeTab={verticalActiveTab2}
                  className="text-muted mt-4 mt-md-0"
                >
                  <TabPane tabId={1}>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5 className="">On waking up</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {wakingUpFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Breakfast</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {breakfastFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={2}>
                    <Row>
                      <div className="col-12  mb-3">
                        <h5 className="">Mid meal</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {midMealFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Lunch</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {lunchFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={3}>
                    <Row>
                      <div className="col-12 mb-3">
                        <h5 className="">Evening Snack</h5>

                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {eveningSnackFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12 mb-3">
                        <h5 className="">Dinner</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {dinnerFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                      <div className="col-12">
                        <h5 className="">Post Dinner</h5>
                        <Table bordered>
                          <thead className="table-green">
                            <tr>
                              <th className="col-2 text-center">Time</th>
                              <th className="col-10">Description</th>
                            </tr>
                          </thead>
                          <tbody>
                            {postDinnerFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                          </tbody>
                        </Table>
                      </div>
                    </Row>
                  </TabPane>

                  <TabPane tabId={4}>
                    <ul className="view-box bg-light bg-soft">
                      <li>{planNote}</li>
                    </ul>
                  </TabPane>
                </TabContent>
              </div>
            </Row>
          </div>
        </div>
      </Modal>
      {/* -------------- */}

      {/* Add/Edit DietPlan modal */}
      <Modal isOpen={modal} className={"modal-fullscreen"} centered>
        <div className="py-3 px-4 border-bottom-0 bg-light-purple d-flex justify-content-between align-items-center w-100 ">
          <div className="d-flex align-items-center col-6 ">
            <h4 className="fw-bold text-purple mb-0">
              {" "}
              {isEdit ? "Edit " : "Add "} Diet Plan{" "}
            </h4>
            <div className="ms-4">
              <select
                value={prevPlan}
                onChange={e => setPrevPlan(e.target.value)}
                className="form-field form-select py-2"
                style={{ width: "200px" }}
              >
                <option value="Copy form">Select Date</option>
                {activePlans?.map(plan => (
                  <option key={plan.date} value={plan.date}>
                    {moment(plan.date).format("DD-MM-YYYY") + " | " + plan.day}
                  </option>
                ))}
              </select>
            </div>

            <button
              onClick={fetchDietPlan2}
              className="btn-success btn-green border-radius btn ms-3"
            >
              {loading2 ? "Getting Details..." : "Copy form"}
            </button>
          </div>

          {/* {!!isEdit ? "Edit Event" : "Add Diet Plan"} */}
          <div className="text-end ms-5 ps-5">
            {isEdit ? (
              <button
                type="submit"
                className="btn btn-green border-radius px-3 bg-light-yellow me-3"
                id="btn-save-event"
                onClick={handleEditDietPlan}
              >
                <i className="fas fa-save me-2 "></i>
                Save Changes
              </button>
            ) : (
              <button
                type="submit"
                className="btn btn-green border-radius px-3 bg-light-yellow me-3"
                id="btn-save-event"
                onClick={handleSaveDietPlan}
              >
                <i className="fas fa-save me-2 "></i>
                Save
              </button>
            )}

            {isEdit && (
              <button
                type="button"
                onClick={onDeletePlan}
                className="btn btn-red btn-green border-radius px-3 me-3"
              >
                <i className="fas fa-trash me-2 "></i>
                Delete This Plan
              </button>
            )}

            <button
              type="button"
              className="btn btn-dark btn-green border-radius px-3"
              onClick={toggle}
            >
              <i className="fas fa-times me-2 "></i>
              Close
            </button>
          </div>
        </div>

        {/* </ModalHeader> */}
        <ModalBody className="p-4 pt-4">
          <Form onSubmit={e => e.preventDefault()}>
            {/* --------------- */}
            <Row className="">
              <div className="col-md-6 position-sticky top-0 h-100">
                {/* Accordian Element */}
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item mb-4 rounded-0">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button text-purple fw-bold fs-5"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        Morning Diet Plan
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div>
                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                On waking up
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      wakingRows,
                                      setWakingRows,
                                      setWakingUpFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(wakingRows || []).map((row, key) => (
                                <div
                                  key={row.id}
                                  className=" position-relative"
                                >
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={wakingUpFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setWakingUpFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>
                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        className="form-field mb-2 pe-5"
                                        value={wakingUpFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setWakingUpFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        placeholder="Enter On waking up "
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle me-3 btn-sm row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              wakingRows,
                                              setWakingRows,
                                              setWakingUpFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Breakfast
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      breakfastRows,
                                      setBreakfastRows,
                                      setBreakfastFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(breakfastRows || []).map((row, key) => (
                                <div
                                  key={row.id}
                                  className=" position-relative"
                                >
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={breakfastFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setBreakfastFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>
                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={breakfastFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setBreakfastFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Breakfast"
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              breakfastRows,
                                              setBreakfastRows,
                                              setBreakfastFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item border-top border-2 mb-4">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button text-purple fw-bold fs-5 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        Afternoon Diet Plan
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div>
                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Mid meal
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      mealRows,
                                      setMealRows,
                                      setMidMealFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(mealRows || []).map((row, key) => (
                                <div
                                  key={row.id}
                                  className=" position-relative"
                                >
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={midMealFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setMidMealFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>
                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={midMealFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setMidMealFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Mid meal "
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              mealRows,
                                              setMealRows,
                                              setMidMealFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Lunch
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      lunchRows,
                                      setLunchRows,
                                      setLunchFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(lunchRows || []).map((row, key) => (
                                <div key={row.id} className="position-relative">
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={lunchFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setLunchFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>

                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={lunchFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setLunchFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Lunch"
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              lunchRows,
                                              setLunchRows,
                                              setLunchFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item border-top border-2 rounded-0">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button text-purple fw-bold fs-5 collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        Evening Diet Plan
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <div>
                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Evening Snack
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      snackRows,
                                      setSnackRows,
                                      setEveningSnackFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(snackRows || []).map((row, key) => (
                                <div
                                  key={row.id}
                                  className=" position-relative"
                                >
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={eveningSnackFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setEveningSnackFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>

                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={eveningSnackFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setEveningSnackFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Evening Snack"
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              snackRows,
                                              setSnackRows,
                                              setEveningSnackFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>

                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Dinner
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      DinnerRows,
                                      setDinnerRows,
                                      setDinnerFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(DinnerRows || []).map((row, key) => (
                                <div key={row.id} className="position-relative">
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={dinnerFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setDinnerFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>

                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={dinnerFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setDinnerFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Dinner"
                                      />

                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              DinnerRows,
                                              setDinnerRows,
                                              setDinnerFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>

                          <Col className="">
                            <div className="mb-3">
                              <div className="form-label d-flex justify-content-between align-items-center">
                                Post Dinner
                                <button
                                  className="btn bg-success text-success bg-soft btn-sm"
                                  onClick={() =>
                                    onAddRow(
                                      postDinnerRows,
                                      setPostDinnerRows,
                                      setPostDinnerFoods
                                    )
                                  }
                                >
                                  <i className="fas fa-plus me-1"></i> Add
                                </button>
                              </div>
                              {(postDinnerRows || []).map((row, key) => (
                                <div key={row.id} className="position-relative">
                                  <div className="row">
                                    <div className="col-3 pe-4">
                                      <input
                                        type="time"
                                        value={postDinnerFoods[key].time}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setPostDinnerFoods,
                                            e.target.value,
                                            "time"
                                          )
                                        }
                                        className="form-field px-1 text-center"
                                      />
                                    </div>

                                    <div className="col-9 ps-md-0">
                                      <Input
                                        name="title"
                                        type="text"
                                        value={postDinnerFoods[key].desc}
                                        onChange={e =>
                                          handleChangeFoodData(
                                            key,
                                            setPostDinnerFoods,
                                            e.target.value,
                                            "desc"
                                          )
                                        }
                                        className="form-field mb-2 pe-5"
                                        placeholder="Enter Post Dinner"
                                      />
                                      {key !== 0 && (
                                        <button
                                          className="btn bg-danger bg-soft text-danger rounded-circle btn-sm me-3 row-delete-btn"
                                          onClick={() =>
                                            onDeleteRow(
                                              row.id,
                                              postDinnerRows,
                                              setPostDinnerRows,
                                              setPostDinnerFoods,
                                              key
                                            )
                                          }
                                        >
                                          <i className="fas fa-trash"></i>
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* ========================================================== */}
                <Col className="col-12 mt-4">
                  <div className="mb-3">
                    <Label className="form-label">Note</Label>
                    <textarea
                      name="title"
                      type="text"
                      rows={5}
                      className="form-field"
                      value={planNote}
                      onChange={e => setPlanNote(e.target.value)}
                      placeholder="Enter Note"
                    />
                  </div>
                </Col>
              </div>

              <div className="col-md-6 ps-md-5">
                <div>
                  <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                    Morning Diet Plan
                  </h4>
                  <Row>
                    <div className="col-12">
                      <h5 className="">On waking up</h5>

                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {wakingUpFoods[0].desc.length > 0 &&
                            wakingUpFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-12">
                      <h5 className="">Breakfast</h5>
                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {breakfastFoods[0].desc.length > 0 &&
                            breakfastFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </div>

                <div className="mt-4">
                  <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                    Afternoon Diet Plan
                  </h4>
                  <Row>
                    <div className="col-12">
                      <h5 className="">Mid meal</h5>

                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {midMealFoods[0].desc.length > 0 &&
                            midMealFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-12">
                      <h5 className="">Lunch</h5>
                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {lunchFoods[0].desc.length > 0 &&
                            lunchFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </div>

                <div className="mt-4">
                  <h4 className="border-bottom text-purple border-secondary pb-2 mb-4 border-2">
                    Evening Diet Plan
                  </h4>
                  <Row>
                    <div className="col-12">
                      <h5 className="">Evening Snack</h5>

                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {eveningSnackFoods[0].desc.length > 0 &&
                            eveningSnackFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-12">
                      <h5 className="">Dinner</h5>
                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {dinnerFoods[0].desc.length > 0 &&
                            dinnerFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                    <div className="col-12">
                      <h5 className="">Post Dinner</h5>
                      <Table bordered>
                        <thead className="bg-light bg-soft">
                          <tr>
                            <th className="col-2 text-center">Time</th>
                            <th className="col-10">Description</th>
                          </tr>
                        </thead>
                        <tbody>
                          {postDinnerFoods[0].desc.length > 0 &&
                            postDinnerFoods.map((food, i) => {
                              return (
                                <tr key={i}>
                                  <td className="text-center">{food.time}</td>
                                  <td>{food.desc}</td>
                                </tr>
                              )
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </Row>
                </div>
              </div>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default DietPlan
