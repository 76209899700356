import React, { useEffect, useState } from "react"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import { Row } from "reactstrap"

import Draggable, { DraggableCore } from "react-draggable"
import { Resizable, ResizableBox } from "react-resizable"
import swal from "sweetalert"
import axios from "axios"

import { getEncryptedData, getDecryptedData, Config } from "pages/Config"

const StickyNote = ({ verticalActiveTab, client_id }) => {
  const [noteForm, setNoteForm] = useState(false)

  const [nodeDesc, setNoteDesc] = useState("<p></p>")
  const [loading, setLoading] = useState("Save")

  useEffect(() => {
    if (verticalActiveTab != "1" && verticalActiveTab != "2") {
      setNoteForm(false)
    }
  }, [verticalActiveTab])

  useEffect(() => {
    const formData = new FormData()

    formData.append("client_id", client_id)
    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${Config.apiUrl}/view_floatnote`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        if (
          response.data.data == "note not found" ||
          response.data.data.description === null
        ) {
          setNoteDesc("<p></p>")
        } else {
          setNoteDesc(getDecryptedData(response.data.data.description))
        }
      })
  }, [])

  const handleAddNote = async e => {
    e.preventDefault()
    setLoading("Saving ...")

    try {
      const formData = new FormData()

      formData.append("description", getEncryptedData(nodeDesc))
      formData.append("client_id", client_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/add_floatnote`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setLoading("Note Saved ✔")

      setTimeout(() => setLoading("Save"), 1400)
    } catch (error) {
      console.log(error)
      setLoading("Save")
      swal("Something went wrong", "", "error")
    }
  }
  const [isStopDragable, setStopDragable] = useState(false)

  return (
    <div>
      <div className={`note-sticky-form ${noteForm ? "d-block" : "d-none"}`}>
        <Draggable
          handle={`${isStopDragable ? ".modal-header" : ""}`}
          cancel={`${isStopDragable ? ".modal-body" : ""}`}
        >
          <ResizableBox
            width={500}
            style={{
              position: "fixed",
              top: "20%",
              right: "2%",
              zIndex: "9999", // Adjusted zIndex
              userSelect: "text",
              pointerEvents: "auto", // Ensuring pointer events are enabled
            }}
          >
            <div style={{ userSelect: "text" }}>
              <form onSubmit={handleAddNote}>
                <Row>
                  <div className="d-flex justify-content-between">
                    <div
                      onDoubleClick={() => setStopDragable(true)}
                      onMouseLeave={() => setStopDragable(false)}
                      onMouseEnter={() => setStopDragable(true)}
                    >
                      <h4 className="fw-bold text-purple mt-2">Edit Notes</h4>
                    </div>
                    <div className="mb-3 text-end">
                      <button
                        type="submit"
                        className="btn border-radius btn-purple"
                      >
                        <i className="fas fa-save me-2"></i>
                        {loading}
                      </button>
                      <button
                        type="button"
                        onClick={() => setNoteForm(false)}
                        className="btn border-radius btn-secondary ms-2"
                      >
                        <i className="fas fa-times fs-6 me-2"></i>
                        Close
                      </button>
                    </div>
                  </div>

                  <div
                    className="col-12"
                    onDoubleClick={() => setStopDragable(true)}
                    onMouseLeave={() => setStopDragable(false)}
                    onMouseEnter={() => setStopDragable(true)}
                  >
                    <CKEditor
                      editor={ClassicEditor}
                      data={nodeDesc}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setNoteDesc(data)
                      }}
                    />
                  </div>
                </Row>
              </form>
            </div>
          </ResizableBox>
        </Draggable>
      </div>

      <button
        className={`sticy-note-btn ${
          verticalActiveTab === "1" || verticalActiveTab === "2"
            ? "d-block"
            : "d-none"
        }`}
        onClick={() => setNoteForm(!noteForm)}
      >
        View Notes
      </button>
    </div>
  )
}

export default StickyNote
