import React, { useState, useEffect, Fragment, useMemo } from "react";
import { Col, Container, Row, Table, Button } from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { Card } from "reactstrap";

import { Link } from "react-router-dom";
import { CardBody } from "reactstrap";
import SearchFilter from "components/Common/SearchFilter"

import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
} from "react-table"
import axios from "axios";
import swal from "sweetalert";
import Loader from "pages/Loader";
import { Config } from "pages/Config";

const Files = (props) => {

    const [allFiles, setAllFiles] = useState([])
    const [uploadedFile, setUploadedFile] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const formData = new FormData()
        formData.append("client_id", props.client_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios.post(`${Config.apiUrl}/files`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            setAllFiles(response.data.data)
        })
    }, [])

    const onDeleteFile = (file_id) => {
        swal({
            title: "Are you sure?",
            text: "do you really want to remove this File",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const formData = new FormData()

                    formData.append("file_id", file_id)
                    formData.append("client_id", props.client_id)
                    formData.append("added_by", localStorage.getItem("adminId"))

                    axios.post(`${Config.apiUrl}/delete_file`, formData, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        }
                    }).then((response) => {
                        setAllFiles(response.data.data)

                        swal("File has been deleted successfully!", {
                            icon: "success",
                        });
                    })
                        .catch((err) => {
                            swal("Something Went Wront", "", "error")
                            console.log(err)
                        })
                }
            });
    }

    // table columns
    const columns = useMemo(
        () => [
            {
                Header: "ID",
                accessor: "id",
                className: "col-1",
                Cell: cellProps => <span>{cellProps.row.index + 1}</span>
            },
            {
                Header: "Name",
                accessor: "name",
                isInfoIcon: true
            },
            {
                Header: "Action",
                accessor: "action",
                className: "col-3 text-center",
                Cell: cellProps => {
                    return <div>
                        <a href={cellProps.row.original.url} download={true} target="_blank" rel="noreferrer" className='btn action-btn btn-sm me-2 btn-purple'>
                            <i className="fas fa-download me-1"></i> Download
                        </a>
                        <button onClick={() => onDeleteFile(cellProps.row.original.id)} className='btn btn-sm action-btn btn-red'>
                            <i className="fas fa-trash-alt"></i>
                        </button>
                    </div>
                },
            }
        ],
        []
    );


    const data = useMemo(() => allFiles, [allFiles])

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination
    )

    const generateSortingIndicator = column => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
    }

    const onChangeInSelect = event => {
        setPageSize(Number(event.target.value))
    }

    const handleFileUpload = (e) => {
        e.preventDefault()
        setLoading(true)
        const formData = new FormData()

        formData.append("file", uploadedFile)
        formData.append("client_id", props.client_id)
        formData.append("added_by", localStorage.getItem("adminId"))

        axios.post(`${Config.apiUrl}/add_file`, formData, {
            headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
            }
        }).then((response) => {
            setAllFiles(response.data.data)
            swal("", "File Uploaded Successfully!", "success")
            setUploadedFile('')
            setLoading(false)
        }).catch((err) => {
            console.log(err)
            swal("Something went wrong", "", "error")
            setLoading(false)
        })
    }

    return (
        <React.Fragment>
            {/* <div className="page-content"> */}
            {/* <Container fluid> */}
            {
                loading && <Loader />
            }
            <Row className="mx-0">
                <Card>
                    <CardBody className="py-4 px-3">
                        <Row className=" justify-space-between flex-md-row flex-column-reverse">
                            <form onSubmit={handleFileUpload}>
                                <Col md={12} className="d-flex justify-content-start align-items-end">
                                    <div className="">
                                        <label htmlFor="" className="fs-5">Upload File</label>
                                        <input type="file" onChange={(e) => setUploadedFile(e.target.files[0])} required className='form-control border-radius' />
                                    </div>
                                    <button type="submit" className="btn btn-purple  border-radius ms-3">
                                        <i className="fas fa-upload me-2"></i>
                                        Upload
                                    </button>
                                </Col>
                            </form>
                            {/* <Col md={8} className="text-end">
                                <Link className="btn btn-green bg-light-yellow border-radius ms-3">
                                    <i className="fas fa-upload me-2"></i>
                                    Upload
                                </Link>
                            </Col> */}
                            <Col md={2} className="mt-4">
                                <select
                                    className="form-select border-radius d-md-block d-none"
                                    value={pageSize}
                                    onChange={onChangeInSelect}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>

                                <div className="d-md-none mt-3">
                                    <SearchFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </div>
                            </Col>
                            <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start mt-4">
                                <div className="d-md-block d-none">
                                    <SearchFilter
                                        preGlobalFilteredRows={preGlobalFilteredRows}
                                        globalFilter={state.globalFilter}
                                        setGlobalFilter={setGlobalFilter}
                                    />
                                </div>

                                <select
                                    className="form-select d-md-none w-50 border-radius"
                                    value={pageSize}
                                    onChange={onChangeInSelect}
                                >
                                    {[10, 20, 30, 40, 50].map(pageSize => (
                                        <option key={pageSize} value={pageSize}>
                                            Show {pageSize}
                                        </option>
                                    ))}
                                </select>

                                {/* <Link to="/nutriconnect-pro/admin-dashboard" className="btn btn-dark border-radius ms-4">
                                    <i className="fas fa-arrow-left me-3"></i>
                                    Back
                                </Link> */}

                            </div>
                        </Row>

                        <div className="table-responsive mt-2">
                            <Table
                                bordered
                                {...getTableProps()}
                                className="w-800 responsive-table"
                                style={{ verticalAlign: 'middle' }}
                            >
                                <thead className="table-green table-nowrap">
                                    {headerGroups.map(headerGroup => (
                                        <tr
                                            key={headerGroup.id}
                                            {...headerGroup.getHeaderGroupProps()}
                                        >
                                            {headerGroup.headers.map(column => (
                                                <th key={column.id} className={column.className}>
                                                    <div {...column.getSortByToggleProps()}>
                                                        {column.render("Header")}
                                                        {generateSortingIndicator(column)}
                                                    </div>
                                                    {/* <Filter column={column} /> */}
                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>

                                <tbody className="row-hover" {...getTableBodyProps()}>
                                    {page.map(row => {
                                        prepareRow(row)
                                        return (
                                            <Fragment key={row.getRowProps().key}>
                                                <tr >
                                                    {row.cells.map(cell => {
                                                        return (
                                                            <td key={cell.id} data-label={cell.column.Header} {...cell.getCellProps()} className={cell.column.className}>
                                                                {cell.render("Cell")}
                                                            </td>
                                                        )
                                                    })}
                                                </tr>

                                            </Fragment>
                                        )
                                    })}

                                    {
                                        page.length === 0 &&
                                        <tr>
                                            <td colSpan={7} className="text-center">No File Found</td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                        </div>

                        <Row className="justify-content-md-end justify-content-center align-items-center mt-3">
                            <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                    <Button
                                        className="btn-green bg-light-purple"
                                        onClick={() => gotoPage(0)}
                                        disabled={!canPreviousPage}
                                    >
                                        {"<<"}
                                    </Button>
                                    <Button
                                        className="btn-green bg-light-purple"
                                        onClick={previousPage}
                                        disabled={!canPreviousPage}
                                    >
                                        {"<"}
                                    </Button>
                                </div>
                            </Col>
                            <Col className="col-md-auto d-none d-md-block">
                                Page{" "}
                                <strong>
                                    {pageIndex + 1} of {pageOptions.length}
                                </strong>
                            </Col>

                            <Col className="col-md-auto">
                                <div className="d-flex gap-1">
                                    <Button
                                        className="btn-green bg-light-purple"
                                        onClick={nextPage}
                                        disabled={!canNextPage}
                                    >
                                        {">"}
                                    </Button>
                                    <Button
                                        className="btn-green bg-light-purple"
                                        onClick={() => gotoPage(pageCount - 1)}
                                        disabled={!canNextPage}
                                    >
                                        {">>"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Row>

            {/* </Container> */}
            {/* </div> */}
        </React.Fragment>
    );
};


export default Files;
