import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import axios from "axios"
import swal from "sweetalert"
import { Config } from "pages/Config"

const ResetPassword = () => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [show_pass, setShowPass] = useState(true)

  const [resetPwd, setResetPwd] = useState("")
  const [confirmPwd, setConfirmPwd] = useState("")

  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("adminUser") && localStorage.getItem("adminId")) {
      navigate(`${Config.appUrl}/admin-dashboard`)
    }
  }, [])

  //meta title
  document.title = "Reset Password | Nutri Connect Pro "

  const handleResetPassword = async e => {
    e.preventDefault()
    try {
      if (resetPwd === confirmPwd) {
        const formData = new FormData()
        formData.append("id", localStorage.getItem("superId"))
        formData.append("password", resetPwd)

        const response = await axios.post(
          `${Config.apiUrl}/reset_password_sadmin`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        if (response.data.message == "password reset") {
          setResetPwd("")
          setConfirmPwd("")
          swal("", "Password Reset Successfully", "success")
        }
      } else {
        swal("Confirm Pasword is not Correct")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title={"Dashboard"} breadcrumbItem={"Reset Password"} />

          <Row>
            <Card>
              <CardBody>
                <form onSubmit={handleResetPassword} className="py-2">
                  <Row className="align-items-end">
                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Enter New Password{" "}
                        <span className="text-danger">*</span>
                      </label>
                      <input
                        type={!show_pass ? "text" : "password"}
                        value={resetPwd}
                        onChange={e => setResetPwd(e.target.value)}
                        placeholder="Enter New Password"
                        className="form-field"
                        required
                      />

                      <div
                        className="bd-eye-pass pe-3"
                        onClick={() => setShowPass(!show_pass)}
                      >
                        <i
                          className={
                            show_pass ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="col-md-4 mt-4">
                      <label htmlFor="" className="fs-5">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <input
                        type={!show_pass ? "text" : "password"}
                        value={confirmPwd}
                        onChange={e => setConfirmPwd(e.target.value)}
                        placeholder="Enter Confirm Password"
                        className="form-field"
                        required
                      />

                      <div
                        className="bd-eye-pass pe-3"
                        onClick={() => setShowPass(!show_pass)}
                      >
                        <i
                          className={
                            show_pass ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                        ></i>
                      </div>
                    </div>

                    <div className="col-md-4 mb-1">
                      <button
                        type="submit"
                        className="btn btn-green bg-light-yellow border-radius"
                      >
                        {" "}
                        <i className="bx bx-send me-1"></i> Reset Password{" "}
                      </button>
                      <button
                        type="button"
                        onClick={() => navigate(-1)}
                        className="btn btn-dark border-radius ms-3"
                      >
                        <i className="fas fa-arrow-left me-3"></i>
                        Back
                      </button>
                    </div>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ResetPassword
