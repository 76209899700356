import CryptoJS from "crypto-js";

// export const SECRET_KEY = "NUT22ZJIUTLHIAUSS77TRI";

export const SECRET_KEY = "75499b36395bb221004247f300ec8a06="

export const getEncryptedData = (normalText) => {
    if (normalText) {
        try {
            const data = CryptoJS.AES.encrypt(
                JSON.stringify(normalText),
                SECRET_KEY
            ).toString();

            return (data);
        } catch (error) {
            console.log("enc Invalid Key")
        }
    }
};

export const getDecryptedData = (encryptedText) => {
    if (encryptedText && encryptedText != "undefined" && encryptedText != "null") {
        try {
            const bytes = CryptoJS.AES.decrypt(encryptedText, SECRET_KEY);
            const data = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return (data);

        } catch (error) {
            console.log("dec Invalid Key")
            return encryptedText
        }
    } else {
        return encryptedText
    }
};
export const Config = {
    appUrl:"",//ztpl
    // apiUrl:"https://ztpl.net/nutriconnect-pro/backend/api" //ztpl
    apiUrl: "https://api.nutriconnectpro.com/api",
}