import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

// Import Components
import MonthlyEarning from "./MonthlyEarning"
import WelcomeComp from "./WelcomeComp"
import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

//redux
import { getChartsData as onGetChartsData } from "../../store/actions"
import { useSelector, useDispatch } from "react-redux"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Loader"
import { Config } from "pages/Config"

const Dashboard = props => {
  const { chartsData } = useSelector(state => ({
    chartsData: state.Dashboard.chartsData,
  }))

  const [periodData, setPeriodData] = useState([])
  const [periodType, setPeriodType] = useState("yearly")

  const [allDietician, setAllDietician] = useState("")
  const [activeDietician, setActiveDietician] = useState("")
  const [inActiveDietician, setInActiveDietician] = useState("")
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("adminUser") && localStorage.getItem("adminId")) {
      navigate(`${Config.appUrl}/admin-dashboard`)
    }
  }, [])

  useEffect(() => {
    setPeriodData(chartsData)
  }, [chartsData])

  useEffect(() => {
    const getDieticianData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${Config.apiUrl}/view_all_dietician`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        setAllDietician(response.data.data.length)

        setActiveDietician(
          response.data.data.filter(diet => diet.is_active == 1).length
        )
        setInActiveDietician(
          response.data.data.filter(diet => diet.is_active == 0).length
        )
        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getDieticianData()
  }, [])

  const onChangeChartPeriod = pType => {
    setPeriodType(pType)
    dispatch(onGetChartsData(pType))
  }

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(onGetChartsData("yearly"))
  }, [dispatch])

  //meta title
  document.title = "Dashboard | Nutri Connect Pro "

  const reports = [
    {
      title: "All Dieticians",
      iconClass: "fas fa-users",
      description: allDietician,
      color: "purple",
      textColor: "#3b2d8e",
      background: "#d5f5f6",
    },
    {
      title: "Active Dieticians",
      iconClass: "fas fa-check-circle ",
      description: activeDietician,
      color: "pink",
      textColor: "#911b91",
      background: "#e6e6fa",
    },
    {
      title: "Inactive Dieticians",
      iconClass: "bx bx-purchase-tag-alt",
      description: inActiveDietician,
      color: "yellow",
      textColor: "#735521",
      background: "#eed1c1",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Breadcrumbs
        title={props.t("Dashboard")}
        breadcrumbItem={props.t("Dashboard")}
      />

      <div className="page-content">
        <Container fluid>
          <Row className="px-md-0 px-3 mb-5">
            <Col xl={12} className=" px-0 mt-1">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody
                        className="shadow-card card-stats"
                        style={{
                          background: report.background,
                          borderBottom: `3px solid ${report.textColor}`,
                          borderRadius: "5px",
                        }}
                      >
                        <Link to={report.link} className="text-dark">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-muted fw-medium fs-5">
                              {report.title}
                            </p>
                            <div className="   align-self-center mini-stat-icon mb-2">
                              <span
                                className={`avatar-title rounded-circle icon-card`}
                              >
                                <i
                                  className={report.iconClass + " fs-5"}
                                  style={{ color: report.textColor }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1 ">
                            <h3 className="mb-0">{report.description}</h3>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row className="mt-3">
                <div className="col-12">
                  <div className="row mx- text-center">
                    <div className="col-md-6">
                      <div
                        className="add-dieticians rounded p-4  shadow-card color-card followup card-body"
                        style={{ backgroundColor: "#beb2ff20" }}
                      >
                        <h4 className="text-dark fw-bold mb-4">
                          <i className="fas fa-user-plus pe-3 border-end border-dark"></i>{" "}
                          <span className="ps-2">Add Dieticians</span>
                        </h4>

                        <Link
                          to={`${Config.appUrl}/add-dietician`}
                          className="btn btn-green btn-purple border-radius px-4 py-2"
                        >
                          <i className="fas fa-plus me-2"></i> Add Dietician
                        </Link>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div
                        className="view-dieticians rounded p-4 shadow-card color-card followup card-body"
                        style={{ backgroundColor: "#beb2ff20" }}
                      >
                        <h4 className="text-dark fw-bold mb-4">
                          <i className="fas fa-users pe-3 border-end border-dark"></i>{" "}
                          <span className="ps-2">View Dieticians</span>
                        </h4>

                        <Link
                          to={`${Config.appUrl}/view-dietician`}
                          className="btn btn-green btn-purple border-radius px-4 py-2"
                        >
                          <i className="fas fa-eye me-2"></i> View Dietician
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(Dashboard)
