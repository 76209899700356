import React, { useEffect, useRef, useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import logo from "../../assets/images/logo-2.png"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import withRouter from "components/Common/withRouter"
import { Link } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { Config, getDecryptedData } from "pages/Config"

const SidebarContent = props => {
  const ref = useRef()
  const activateParentDropdown = useCallback(item => {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }, [])

  const removeActivation = items => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i]
      const parent = items[i].parentElement

      if (item && item.classList.contains("active")) {
        item.classList.remove("active")
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show")
        }

        parent.classList.remove("mm-active")
        const parent2 = parent.parentElement

        if (parent2) {
          parent2.classList.remove("mm-show")

          const parent3 = parent2.parentElement
          if (parent3) {
            parent3.classList.remove("mm-active") // li
            parent3.childNodes[0].classList.remove("mm-active")

            const parent4 = parent3.parentElement // ul
            if (parent4) {
              parent4.classList.remove("mm-show") // ul
              const parent5 = parent4.parentElement
              if (parent5) {
                parent5.classList.remove("mm-show") // li
                parent5.childNodes[0].classList.remove("mm-active") // a tag
              }
            }
          }
        }
      }
    }
  }

  const path = useLocation()
  const activeMenu = useCallback(() => {
    const pathName = path.pathname
    let matchingMenuItem = null
    const ul = document.getElementById("side-menu")

    const items = ul.getElementsByTagName("a")

    removeActivation(items)

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i]
        break
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem)
    }
  }, [path.pathname, activateParentDropdown])

  useEffect(() => {
    ref.current.recalculate()
  }, [])

  useEffect(() => {
    new MetisMenu("#side-menu")
    activeMenu()
  }, [])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" })
    activeMenu()
  }, [activeMenu])

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  const [settings, setSetting] = useState(false)
  useEffect(() => {
    if (
      path.pathname == `${Config.appUrl}/general-settings` ||
      path.pathname == `${Config.appUrl}/invoice-settings`
    ) {
      setSetting(true)
    } else {
      setSetting(false)
    }
  }, [path.pathname])

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <div className="d-flex p-3 justify-content-between align-items-center">
            {/* <Link to={`${Config.appUrl}/admin-dashboard`}> */}
              <img
                className="main-logo"
                src={logo}
                style={{ height: "65px" }}
              ></img>
            {/* </Link> */}
            <div className="text-start flex-grow-1 menu-side-welcome">
              <h3 className="sidebar-title mb-0">Welcome </h3>
              <h5 className="mb-0 card-title ">
                {getDecryptedData(localStorage.getItem("adminUser"))}
              </h5>
            </div>
          </div>

          <ul className="metismenu list-unstyled" id="side-menu">
            {localStorage.getItem("adminUser") &&
            localStorage.getItem("adminId") ? (
              <>
                <li>
                  <Link to={`${Config.appUrl}/admin-dashboard`}>
                    <i className="bi bi-house"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/all-clients`}>
                    <i className="bi bi-people"></i>
                    <span>{props.t("All Clients")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/add-diet-plan`}>
                    <i className="bi bi-journal"></i>
                    <span>{props.t("Diet Plans")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/invoices`}>
                    <i className="bi bi-currency-rupee"></i>
                    <span>{props.t("Invoices")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/packages`}>
                    <i className="bi bi-box"></i>
                    <span>{props.t("Packages")}</span>
                  </Link>
                </li>
                <li>
                  <a onClick={() => setSetting(true)}>
                    <i className="bi bi-gear"></i>
                    <span>{props.t("Settings")}</span>
                  </a>
                </li>
                {settings && (
                  // <div className="sub-menu">
                  <>
                    <li>
                      <Link to={`${Config.appUrl}/general-settings`}>
                        <i className="bi bi-info-circle"></i>
                        <span>{props.t("General Details")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to={`${Config.appUrl}/invoice-settings`}>
                        <i className="bi bi-receipt"></i>
                        <span>{props.t("Invoice Details")}</span>
                      </Link>
                    </li>
                  </>
                  // </div>
                )}
              </>
            ) : (
              <>
                <li>
                  <Link to={`${Config.appUrl}/dashboard`}>
                    <i className="bi bi-house"></i>
                    <span>{props.t("Dashboard")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/add-dietician`}>
                    <i className="bi bi-person-plus"></i>
                    <span>{props.t("Add Dieticians")}</span>
                  </Link>
                </li>
                <li>
                  <Link to={`${Config.appUrl}/view-dietician`}>
                    <i className="bi bi-people"></i>
                    <span>{props.t("View Dieticians")}</span>
                  </Link>
                </li>
              </>
            )}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
