import React from "react"
import PropTypes from "prop-types"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"
import { Config } from "pages/Config"

const Breadcrumb = props => {
  const navigate = useNavigate()
  const logoutAdmin = () => {
    localStorage.removeItem("sAdminUser")
    localStorage.removeItem("superId")
    localStorage.removeItem("adminUser")
    localStorage.removeItem("adminId")
    localStorage.removeItem("created_at")
    navigate(`${Config.appUrl}/login`)
  }

  function tToggle() {
    var body = document.body
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable")
    } else {
      body.classList.toggle("vertical-collpsed")
      body.classList.toggle("sidebar-enable")
    }
  }
  return (
    <Row>
      <Col className="col-12 p-0">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center gap-2 justify-space-between col-12">
            <div className="d-flex align-items-center gap-2">
              <a
                onClick={() => {
                  tToggle()
                }}
                className="font-size-13"
              >
                <i className="fa fa-fw fa-bars"></i>
              </a>
              <h4 className="mb-sm-0 font-size-18">{props.breadcrumbItem}</h4>
            </div>

            <div className="page-title-right">
              <ol className="breadcrumb m-0">
                <BreadcrumbItem>
                  <div
                    className="d-flex align-items-center logout-btn"
                    onClick={logoutAdmin}
                  >
                    <i className="bi bi-box-arrow-right me-2"></i>
                    <p style={{ marginBottom: "0" }} >Leave Now</p>
                  </div>
                </BreadcrumbItem>
              </ol>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.string,
  title: PropTypes.string,
}

export default Breadcrumb
