import React, { useState, useEffect, Fragment, useMemo } from "react"
import {
  Col,
  Container,
  DropdownMenu,
  DropdownToggle,
  Modal,
  Row,
  UncontrolledDropdown,
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb"
import { Card, Button, Table } from "reactstrap"
import SearchFilter from "components/Common/SearchFilter"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import { Config, getDecryptedData } from "pages/Config"

import {
  useTable,
  useGlobalFilter,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
} from "react-table"
import axios from "axios"
import swal from "sweetalert"
import Loader from "pages/Loader"
import moment from "moment"

const ViewDieticians = () => {
  const [dieticianData, setDieticianData] = useState([])
  const [currDietician, setCurrDietician] = useState(null)
  const [viewDieticianModal, setViewDieticianModal] = useState(false)

  const [loading, setLoading] = useState(false)

  //meta title
  document.title = "View Dieticians | Nutri Connect Pro "

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("adminUser") && localStorage.getItem("adminId")) {
      navigate("/admin-dashboard")
    }
  }, [])

  useEffect(() => {
    const getDieticianData = async () => {
      setLoading(true)
      try {
        const response = await axios.get(
          `${Config.apiUrl}/view_all_dietician`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        const decryptedData = response.data.data.map(edata => ({
          ...edata,
          firstname: getDecryptedData(edata.firstname),
          additional_detail: getDecryptedData(edata.additional_detail),
          age: getDecryptedData(edata.age),
          gender: getDecryptedData(edata.gender),
          height: getDecryptedData(edata.height),
          lastname: getDecryptedData(edata.lastname),
          package: getDecryptedData(edata.package),
          phone: getDecryptedData(edata.phone),
          weight: getDecryptedData(edata.weight),
        }))
        setDieticianData(decryptedData)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getDieticianData()
  }, [])

  // changeStatus
  const changeStatus = (status, d_id) => {
    swal({
      title: "Are you sure?",
      text: `you want to ${status == 1 ? "InActive" : "Active"} this Dietician`,
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("status", status == 1 ? 0 : 1)
        formData.append("dietician_id", d_id)

        axios
          .post(`${Config.apiUrl}/dietician_status`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            const decryptedData = response.data.data.map(edata => ({
              ...edata,
              firstname: getDecryptedData(edata.firstname),
              additional_detail: getDecryptedData(edata.additional_detail),
              age: getDecryptedData(edata.age),
              gender: getDecryptedData(edata.gender),
              height: getDecryptedData(edata.height),
              lastname: getDecryptedData(edata.lastname),
              package: getDecryptedData(edata.package),
              phone: getDecryptedData(edata.phone),
              weight: getDecryptedData(edata.weight),
            }))
            setDieticianData(decryptedData)
          })
      } else {
        setDieticianData(prevData => [...prevData])
      }
    })
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        className: "text-center",
        Cell: cellProps => {
          return <span>{cellProps.row.index + 1} </span>
        },
      },
      {
        Header: "Dietician Name",
        accessor: "firstname",
        disableFilters: true,
        filterable: true,
        Cell: cellProps => {
          return (
            <span>
              {cellProps.row.original.firstname +
                " " +
                cellProps.row.original.lastname}{" "}
            </span>
          )
        },
      },
      {
        Header: "Email Address",
        accessor: "email",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Phone Number",
        accessor: "phone",
        disableFilters: true,
        filterable: true,
        className: "col-1 text-center",
      },
      {
        Header: "Package Type",
        accessor: "package",
        disableFilters: true,
        filterable: true,
        className: "col-2 text-center",
        Cell: cellProps => {
          return (
            <span>
              {cellProps.row.original.package == 0 ? "Trial Package" : "-"}
            </span>
          )
        },
      },
      {
        Header: "Start Date",
        accessor: "created_at",
        disableFilters: true,
        filterable: true,
        className: "col-2 text-center",
        Cell: cellProps => {
          return (
            <span>
              {moment(cellProps.row.original.created_at).format("DD-MM-YYYY")}
            </span>
          )
        },
      },
      {
        Header: "Expire Date",
        accessor: "end_date",
        disableFilters: true,
        filterable: true,
        className: "col-2 text-center",
        Cell: cellProps => {
          function addDays(date) {
            var result = new Date(date)
            result.setDate(result.getDate() + 60)
            return result
          }
          console.log()

          return (
            <span>
              {moment(addDays(cellProps.row.original.created_at)).format(
                "DD-MM-YYYY"
              )}
            </span>
          )
        },
      },
      {
        Header: "Status",
        accessor: "status",
        disableFilters: true,
        filterable: true,
        className: "col-1 text-center",
        Cell: cellProps => {
          return (
            <div className="text-center">
              <div className="form-switch form-switch-md">
                <input
                  type="checkbox"
                  className="form-check-input second_view"
                  onChange={() =>
                    changeStatus(
                      cellProps.row.original.is_active,
                      cellProps.row.original.id
                    )
                  }
                  style={{ cursor: "pointer" }}
                  defaultChecked={
                    cellProps.row.original.is_active == 1 ? true : false
                  }
                />
              </div>
            </div>
          )
        },
      },
      {
        Header: "Action",
        disableFilters: true,
        accessor: "view",
        className: "col-1 text-center",
        Cell: cellProps => {
          return (
            <div className="d-flex justify-content-center">
              <UncontrolledDropdown
                className="dropdown"
                style={{ width: "29px" }}
              >
                <DropdownToggle
                  className="btn btn-sm btn-outline-secondary w-100"
                  color="white"
                >
                  <i className="mdi mdi-dots-horizontal"></i>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                  <a
                    onClick={() => {
                      setCurrDietician(cellProps.row.original)
                      setViewDieticianModal(true)
                    }}
                    className="dropdown-item"
                  >
                    <i className="fas fa-eye me-2"></i> View Dietician
                  </a>
                  <Link
                    to={`${Config.appUrl}/edit-dietician/${cellProps.row.original.id}`}
                    className="dropdown-item"
                  >
                    <i className="fas fa-pen me-2"></i> Edit Dietician
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          )
        },
      },
    ],
    [dieticianData]
  )

  const data = useMemo(() => dieticianData, [dieticianData])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  )

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""
  }

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value))
  }
  console.log(dieticianData)

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Breadcrumbs title={"Dashboard"} breadcrumbItem={"View Dieticians"} />

      <div className="page-content">
        <Container fluid>
          <Row>
            <Card className="px-0 mx-0">
              <CardBody>
                <Row className=" justify-space-between flex-md-row flex-column-reverse">
                  <Col md={2}>
                    <select
                      className="form-select border-radius d-md-block d-none"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <div className="d-md-none mt-3">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>
                  </Col>
                  <div className="d-flex col-md-8 justify-content-md-end justify-content-between align-items-start ">
                    <div className="d-md-block d-none">
                      <SearchFilter
                        preGlobalFilteredRows={preGlobalFilteredRows}
                        globalFilter={state.globalFilter}
                        setGlobalFilter={setGlobalFilter}
                      />
                    </div>

                    <select
                      className="form-select d-md-none w-50 border-radius"
                      value={pageSize}
                      onChange={onChangeInSelect}
                    >
                      {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>

                    <Link
                      to={`${Config.appUrl}/dashboard`}
                      className="btn btn-dark border-radius ms-4 back-btn"
                    >
                      <i className="fas fa-arrow-left me-3"></i>
                      Back
                    </Link>
                    <Link
                      to={`${Config.appUrl}/add-dietician`}
                      className="btn btn-purple  border-radius ms-3"
                    >
                      <i className="fas fa-plus me-2"></i>
                      Add Dietician
                    </Link>
                  </div>
                </Row>

                <div className="table-responsives mt-4">
                  <Table
                    bordered
                    {...getTableProps()}
                    className="w-800 responsive-tables responsive ncp-table"
                    style={{ verticalAlign: "middle" }}
                  >
                    <thead className="table-green table-nowrap">
                      {headerGroups.map(headerGroup => (
                        <tr
                          key={headerGroup.id}
                          {...headerGroup.getHeaderGroupProps()}
                        >
                          {headerGroup.headers.map(column => (
                            <th key={column.id} className={column.className}>
                              <div {...column.getSortByToggleProps()}>
                                {column.render("Header")}
                                {generateSortingIndicator(column)}
                              </div>
                              {/* <Filter column={column} /> */}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>

                    <tbody className="row-hover" {...getTableBodyProps()}>
                      {page.map(row => {
                        prepareRow(row)
                        return (
                          <Fragment key={row.getRowProps().key}>
                            <tr>
                              {row.cells.map(cell => {
                                return (
                                  <td
                                    key={cell.id}
                                    data-label={cell.column.Header}
                                    {...cell.getCellProps()}
                                    className={cell.column.className}
                                  >
                                    {cell.render("Cell")}
                                  </td>
                                )
                              })}
                            </tr>
                          </Fragment>
                        )
                      })}

                      {page.length === 0 && (
                        <tr>
                          <td colSpan={7} className="text-center">
                            No Dietician Found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>

                <Row className="justify-content-md-end justify-content-center align-items-center mt-3">
                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(0)}
                        disabled={!canPreviousPage}
                      >
                        {"<<"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={previousPage}
                        disabled={!canPreviousPage}
                      >
                        {"<"}
                      </Button>
                    </div>
                  </Col>
                  <Col className="col-md-auto d-none d-md-block">
                    Page{" "}
                    <strong>
                      {pageIndex + 1} of {pageOptions.length}
                    </strong>
                  </Col>

                  <Col className="col-md-auto">
                    <div className="d-flex gap-1">
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={nextPage}
                        disabled={!canNextPage}
                      >
                        {">"}
                      </Button>
                      <Button
                        className="btn-green bg-light-purple"
                        onClick={() => gotoPage(pageCount - 1)}
                        disabled={!canNextPage}
                      >
                        {">>"}
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>

      <Modal isOpen={viewDieticianModal} centered className="modal-fullscreen">
        <div className="modal-header border-0 px-md-5 bg-light-purple">
          <h4 className="modal-title mt-0 text-dark">
            View Dieticians Details
          </h4>
          <span
            type="button"
            onClick={() => setViewDieticianModal(false)}
            data-dismiss="modal"
            aria-label="Close"
          >
            <i className="fas fa-times fs-5 text-dark"></i>
          </span>
        </div>
        <div className="modal-body">
          <Row>
            <form
              action=""
              onSubmit={e => e.preventDefault()}
              className="pt-4 px-md-5"
            >
              <Row>
                <div className="col-md-4 ">
                  <label htmlFor="" className="fs-5">
                    Enter Name
                  </label>
                  <Row>
                    <div className="col-6">
                      <input
                        type="text"
                        placeholder="First Name"
                        defaultValue={currDietician?.firstname}
                        className="form-field"
                        disabled
                      />
                    </div>
                    <div className="col-6">
                      <input
                        type="text"
                        placeholder="Last Name"
                        defaultValue={currDietician?.lastname}
                        className="form-field"
                        disabled
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-4 ">
                  <label htmlFor="" className="fs-5">
                    Height
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Height"
                    defaultValue={currDietician?.height}
                    className="form-field"
                    disabled
                  />
                </div>
                <div className="col-md-4 ">
                  <label htmlFor="" className="fs-5">
                    Weight
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Your Weight"
                    defaultValue={currDietician?.weight}
                    className="form-field"
                    disabled
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <Row>
                    <div className="col-md-6">
                      <label htmlFor="" className="fs-5">
                        Age
                      </label>
                      <input
                        type="text"
                        placeholder="Enter Age"
                        defaultValue={currDietician?.age}
                        className="form-field"
                        disabled
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="fs-5">
                        Gender
                      </label>
                      <Row className="mt-2 pt-1">
                        <div className="col-6">
                          <input
                            type="radio"
                            name="gender"
                            checked={currDietician?.gender == "male"}
                            className="fs-5 form-check-input"
                            disabled
                          />
                          <label htmlFor="" className="ps-2 pt-1">
                            Male
                          </label>
                        </div>

                        <div className="col-6">
                          <input
                            type="radio"
                            name="gender"
                            checked={currDietician?.gender == "female"}
                            className="form-check-input fs-5 "
                            disabled
                          />
                          <label htmlFor="" className="ps-2 pt-1">
                            Female
                          </label>
                        </div>
                      </Row>
                    </div>
                  </Row>
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Email Address
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Email Address"
                    defaultValue={currDietician?.email}
                    className="form-field"
                    disabled
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Password
                  </label>
                  <input
                    type="text"
                    value={currDietician?.password}
                    className="form-field"
                    disabled
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Phone Number"
                    className="form-field"
                    defaultValue={currDietician?.phone}
                    disabled
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Package
                  </label>
                  <select
                    defaultValue={currDietician?.package}
                    className="form-field"
                    disabled
                  >
                    <option value="">Nothing Selected</option>
                    <option value="0">Trial Package (60 days)</option>
                  </select>
                </div>

                {/* <div className="col-md-6 mt-4">
                                    <label htmlFor="" className="fs-5">Please select all of them below that describe you </label>
                                    <Row className="mt-2 pt-1">
                                        <div className="col-4">

                                            <input type="checkbox" defaultChecked={true} className="fs-5 form-check-input " disabled />
                                            <label htmlFor="" className="ps-2 pt-1">I am Vegan</label>
                                        </div>

                                        <div className="col-4">
                                            <input type="checkbox" className="form-check-input fs-5 " disabled />
                                            <label htmlFor="" className="ps-2 pt-1">I am Vegetarian</label>
                                        </div>

                                        <div className="col-4">
                                            <input type="checkbox" defaultChecked={true} className="form-check-input fs-5 " disabled />
                                            <label htmlFor="" className="ps-2 pt-1">I have food allergies</label>
                                        </div>
                                    </Row>
                                </div> */}

                <div className="col-12 mt-4">
                  <label htmlFor="" className="fs-5">
                    Please give additional detail about your diet here:{" "}
                  </label>
                  <textarea
                    type="text"
                    rows={4}
                    defaultValue={currDietician?.additional_detail}
                    placeholder="Enter Here"
                    className="form-field"
                    disabled
                  />
                </div>
              </Row>
            </form>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  )
}

export default ViewDieticians
