import axios from "axios"
import { Config, getDecryptedData } from "pages/Config"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const AllFollowUps = () => {
  const [allFollowUp, setAllFollowUp] = useState([])
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => {
    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${Config.apiUrl}/followup_list`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setLoading(false)

        const originalData = response.data.data.map(followup => ({
          ...followup,
          description: getDecryptedData(followup.description),
        }))

        setAllFollowUp(originalData)
      })
      .catch(err => console.log(err))
  }, [])

  const onDoneFollowUp = (added_by, follow_id) => {
    swal({
      title: "Are you sure?",
      text: "You are done with this follow up?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
      .then(willDelete => {
        if (willDelete) {
          const formData = new FormData()

          formData.append("followup_id", follow_id)
          formData.append("added_by", added_by)
          axios
            .post(`${Config.apiUrl}/done_followup`, formData, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then(response => {
              const originalData = response.data.data.map(followup => ({
                ...followup,
                description: getDecryptedData(followup.description),
              }))

              setAllFollowUp(originalData)

              swal("Follow up done successfully!", {
                icon: "success",
              })
            })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <>
      <div className="color-div">
        <h5 className="fw-bold mb-3 text-purple">Follow Up</h5>
      </div>
      <div>
        <ul
          className="ps-0"
          style={{
            maxHeight: "360px",
            overflowY: "scroll",
            scrollbarWidth: "none",
          }}
        >
          {allFollowUp.map((followup, i) => {
            return (
              <li
                key={followup.id}
                className={`fs-6 p-3 d-flex ${
                  i % 2 == 0 ? "bg-white" : "bg-white"
                }  justify-content-between align-items-center  shadow-smallcards`}
              >
                <div className="d-flex justify-content-between align-items-center">
                  <button
                    onClick={() => {
                      const url = `${Config.appUrl}/edit-client/${followup.client_id}`
                      const data = {
                        tabIndex: "4",
                        followup_id: followup.id,
                        desc: followup.description,
                        noteId: followup.note_id,
                        date: followup.date,
                      }
                      const queryString = new URLSearchParams(data).toString()
                      window.open(`${url}?${queryString}`, "_blank")
                    }}
                    className="btn  btn-sm btn-edit d-flex justify-content-center bg-custom-grey"
                  >
                    <i className="fas fa-pen"></i>
                  </button>
                  {/* <i className="bx bx-right-arrow-circle font-size-18 me-2"></i> */}
                  <span
                    className="px-2 ps-1 dash-followUp"
                    dangerouslySetInnerHTML={{ __html: followup.description }}
                  ></span>
                </div>

                <div>
                  <button
                    onClick={() =>
                      onDoneFollowUp(followup.added_by, followup.id)
                    }
                    className="btn btn-success btn-sm  d-flex justify-content-center align-items-center"
                    style={{ width: "20px", height: "20px" }}
                  >
                    <i className="fas fa-check "></i>
                  </button>
                </div>
              </li>
            )
          })}
        </ul>
        <div>
          {allFollowUp.length == 0 && "Follow Ups will be appear here.."}
        </div>
      </div>
    </>
  )
}

export default AllFollowUps
