import React, { useEffect, useState } from "react"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap"
import classnames from "classnames"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import Settings from "./Settings"
import InvoiceDetails from "./InvoiceDetails"

// import Loader from "pages/Separate/Loader"

// components

const SettingIndex = () => {
  const [fixedSideTab, setFixedSideTab] = useState(false)

  const [verticalActiveTab, setverticalActiveTab] = useState("1")

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  document.addEventListener("scroll", () => {
    if (window.scrollY > 68) {
      setFixedSideTab(true)
    } else {
      setFixedSideTab(false)
    }
  })

  document.title = "Settings | Nutri Connect Pro"

  const toggleVertical = tab => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab)
    }
  }

  return (
    <React.Fragment>
      <Breadcrumbs title={"Dashboard"} breadcrumbItem={"General Settings"} />
      <div className="page-content mb-4">
        <Settings />
      </div>
    </React.Fragment>
  )
}

export default SettingIndex
