import React, { useEffect, useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dropzone from "react-dropzone"
import { Row, Form, Modal, Card, Col } from "reactstrap"
import Select from "react-select"

// editor
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

// assets
import "../../../../assets/css/Notes.css"
import userImg from "../../../../assets/default-user.png"
import axios from "axios"
import moment from "moment"

import { getEncryptedData, getDecryptedData, Config } from "pages/Config"
import Loader from "pages/Loader"

const Notes = props => {
  const [fixButtons, setFixButtons] = useState(false)
  const [followUp, setFollowUp] = useState(false)
  const [notesSidebar, setNotesSidebar] = useState(false)
  const [editNotesSidebar, setEditNotesSidebar] = useState(false)

  // fields for add note
  const [addNoteDesc, setAddNoteDesc] = useState("<p></p>")
  const [addDate, setAddDate] = useState("")

  // fields for edit note
  const [editNoteDesc, setEditNoteDesc] = useState("")
  const [editDate, setEditDate] = useState("")
  const [noteId, setNoteId] = useState("")
  const [loading, setLoading] = useState(false)

  // for notes view
  const [allNotes, setAllNotes] = useState([])

  // follow up states
  const [followUpDesc, setFollowUpDesc] = useState("")
  const [folllowUpDate, setFollowUpDate] = useState("")

  // getting all notes
  useEffect(() => {
    const getAllNotes = async () => {
      setLoading(true)
      try {
        const formData = new FormData()
        formData.append("client_id", props.client_id)

        const response = await axios.post(
          `${Config.apiUrl}/notes`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        const originalData = response.data.data.map(notes => ({
          ...notes,
          description: getDecryptedData(notes.description),
        }))

        setAllNotes(originalData)

        setLoading(false)
      } catch (error) {
        console.log(error)
      }
    }
    getAllNotes()
  }, [])

  const navigate = useNavigate()

  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const onDeleteNote = note_id => {
    swal({
      title: "Are you sure?",
      text: "do you really want to remove this Note",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then(willDelete => {
      if (willDelete) {
        const formData = new FormData()

        formData.append("note_id", note_id)
        formData.append("client_id", props.client_id)

        axios
          .post(
            `${Config.apiUrl}/delete_note`,
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .then(response => {
            // console.log(response.data)

            if (response.data.data.length > 0) {
              const originalData = response.data.data.map(notes => ({
                ...notes,
                description: getDecryptedData(notes.description),
              }))

              setAllNotes(originalData)
            }

            swal("Note has been deleted successfully!", {
              icon: "success",
            })
          })
      }
    })
  }

  // add note
  const handleAddNote = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("description", getEncryptedData(addNoteDesc))
      formData.append("date", addDate)
      formData.append("client_id", props.client_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/add_note`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      const originalData = response.data.data.map(notes => ({
        ...notes,
        description: getDecryptedData(notes.description),
      }))

      setAllNotes(originalData)

      setLoading(false)
      setNotesSidebar(false)
      swal("Note added successfully!", {
        icon: "success",
      }).then(() => {
        setAddNoteDesc("")
        setAddDate("")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleEditNote = async () => {
    try {
      const formData = new FormData()
      formData.append("description", getEncryptedData(editNoteDesc))
      formData.append("date", editDate)
      formData.append("client_id", props.client_id)
      formData.append("note_id", noteId)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/edit_note`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // console.log(response.data)

      const originalData = response.data.data.map(notes => ({
        ...notes,
        description: getDecryptedData(notes.description),
      }))

      setAllNotes(originalData)

      setEditNotesSidebar(false)

      swal("Note Updated successfully!", {
        icon: "success",
      })
    } catch (error) {
      console.log(error)
    }
  }

  // add note folllow up
  const handleAddFollowup = async e => {
    e.preventDefault()

    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("description", getEncryptedData(followUpDesc))
      formData.append("date", folllowUpDate)
      formData.append("client_id", props.client_id)
      formData.append("note_id", noteId)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/add_followup`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      setLoading(false)

      swal("FollowUp added successfully!", {
        icon: "success",
      }).then(() => {
        setFollowUp(false)
        setFollowUpDesc("")
        setFollowUpDate("")
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const addEditFollowUp = note_id => {
    setNoteId(note_id)

    const formData = new FormData()
    formData.append("note_id", note_id)

    axios
      .post(
        `${Config.apiUrl}/view_followup`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then(response => {
        if (response.data.data.length > 0) {
          setFollowUpDesc(getDecryptedData(response.data.data[0].description))
          setFollowUpDate(response.data.data[0].date)
        } else {
          setFollowUpDesc("")
          setFollowUpDate("")
        }
      })
      .catch(err => console.log(err))

    setFollowUp(true)
  }

  useEffect(() => {
    if (
      props?.receivedData?.followup_id &&
      props?.receivedData?.noteId != "0"
    ) {
      addEditFollowUp(props?.receivedData?.noteId)
    }
  }, [props?.receivedData])

  return (
    <React.Fragment>
      {loading && <Loader />}
      <div className="mb-3">
        <div className="px-3 py-4 bg-white">
          <Form onSubmit={e => e.preventDefault()}>
            {/* Fixed Buttons */}
            <div
              className={`flex-column fixed-buttons ${
                fixButtons ? "d-flex" : "d-none"
              }`}
            >
              <a
                onClick={() => {
                  setAddDate(moment(new Date()).format("YYYY-MM-DD"))
                  setNotesSidebar(true)
                }}
                className="btn btn-purple border-radius fix-btn"
              >
                <i className="fas fa-plus me-2"></i>
                <span>Note</span>
              </a>
              <a
                onClick={() => navigate(-1)}
                className="btn btn-dark border-radius fix-btn"
              >
                <i className="fas fa-arrow-left me-2 icon-center"></i>
                <span>Back</span>
              </a>
            </div>

            <div className="col-12 mb-4 text-end">
              <button
                onClick={() => navigate(-1)}
                className="btn btn-dark border-radius me-3"
              >
                <i className="fas fa-arrow-left me-3"></i>
                Back
              </button>
              <button
                onClick={() => {
                  setAddDate(moment(new Date()).format("YYYY-MM-DD"))
                  setNotesSidebar(true)
                }}
                className="btn btn-green btn-purple border-radius"
              >
                <i className="fas fa-plus me-1"></i> Add Note{" "}
              </button>
            </div>

            {/* Add Notes */}
            <div
              className={`notes-sidebar ${
                notesSidebar ? "show-notes-sidebar" : ""
              }`}
            >
              <Row className="align-items-center justify-content-between">
                <div className="col-12 d-flex justify-content-between">
                  <h5 className="text-purple fw-bold">Add New Note</h5>
                  <div className="text-end">
                    <button
                      onClick={handleAddNote}
                      className="btn btn-purple btn-green border-radius me-2"
                    >
                      <i className="fas fa-plus me-2"></i>{" "}
                      {loading ? "Adding Note.." : "Add Note"}
                    </button>
                    <a
                      onClick={() => setNotesSidebar(false)}
                      className="btn btn-secondary text-uppercase btn-green border-radius"
                    >
                      <i className="fas fa-times fs-6 me-2 "></i>
                      Close
                    </a>
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="">Description</label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={addNoteDesc}
                    onReady={editor => {
                      // You can store the "editor" and use when it is needed.
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData()
                      setAddNoteDesc(data)
                    }}
                    
                  />
                </div>
                <div className="col-md-4 mt-3 notes">
                  <label htmlFor="">Got in touch with this lead</label>
                  <Row>
                    <div className="col-6 pe-md-0">
                      <input
                        type="date"
                        value={addDate}
                        onChange={e => setAddDate(e.target.value)}
                        className="form-control border-radius"
                        required
                      />
                    </div>

                    <div className="col-6">
                      <input
                        type="time"
                        value={moment(new Date()).format("hh:mm")}
                        className="form-control border-radius"
                        required
                      />
                    </div>
                  </Row>
                </div>
              </Row>
            </div>

            <div>
              {/* Activities */}
              {allNotes.length > 0 ? (
                <ul className="verti-timeline list-unstyled notes-box">
                  {allNotes.map((note, key) => (
                    <li key={key} className="event-list position-relative">
                      <div className="event-timeline-dot">
                        <img src={userImg} className="userImg me-3 " alt="" />
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-1 ms-4">
                          <div className="note">
                            <div className=" d-flex justify-content-between py-2 rounded ps-4">
                              <div>
                                <p className="text-muted mb-3 mt-1">
                                  <h6 className="d-inline me-2 fw-bold text-purple">
                                    By{" "}
                                    {getDecryptedData(
                                      localStorage.getItem("adminUser")
                                    )}
                                  </h6>
                                  {moment(note.created_at).format(
                                    "DD-MM-YYYY | hh:mm:s"
                                  )}
                                </p>
                                <i className="bi bi-triangle-fill note-icon"></i>
                              </div>
                              <div className="me-4">
                                <span
                                  onClick={() => addEditFollowUp(note.id)}
                                  className="me-2 cursor-pointer badge-soft-success badge"
                                >
                                  follow up
                                </span>
                                <button
                                  onClick={e => {
                                    e.preventDefault()

                                    setEditNoteDesc(note.description)
                                    setEditDate(note.date)
                                    setNoteId(note.id)

                                    setEditNotesSidebar(true)
                                  }}
                                  className="btn btn-sm edit-delete-btn text-success me-2"
                                >
                                  <i className="fas fa-edit" title="Edit"></i>
                                </button>
                                <a
                                  onClick={() => onDeleteNote(note.id)}
                                  className="btn btn-sm edit-delete-btn text-danger"
                                >
                                  <i
                                    className="fas fa-trash-alt"
                                    title="Delete"
                                  ></i>
                                </a>
                              </div>
                            </div>
                            <div className="p-4 pt-0 pb-3">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: note.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <h5 className="ms-4">Notes will be appear here once added </h5>
              )}

              {/* Edit Notes Sidebar */}
              <div
                className={`notes-sidebar ${
                  editNotesSidebar ? "show-notes-sidebar" : ""
                }`}
              >
                <Row className="align-items-center justify-content-between">
                  <div className="col-12 d-flex justify-content-between">
                    <h5 className="fw-bold text-purple">Edit Note</h5>
                    <div className="text-end">
                      <button
                        onClick={handleEditNote}
                        className="btn btn-purple btn-green border-radius me-2"
                      >
                        <i className="fas fa-edit me-2"></i>Edit Note
                      </button>
                      <a
                        onClick={() => setEditNotesSidebar(false)}
                        className="btn btn-secondary btn-green border-radius"
                      >
                        <i className="fas fa-times fs-6 me-2 "></i>
                        Close
                      </a>
                    </div>
                  </div>
                  <div className="col-12">
                    <label htmlFor="">Description</label>
                    <CKEditor
                      editor={ClassicEditor}
                      data={editNoteDesc}
                      onReady={editor => {
                        // You can store the "editor" and use when it is needed.
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData()
                        setEditNoteDesc(data)
                      }}
                    />
                  </div>
                  <div className="col-md-4 mt-3 notes">
                    <label htmlFor="">Got in touch with this lead</label>
                    <Row>
                      <div className="col-6 pe-md-0">
                        <input
                          type="date"
                          defaultValue={editDate}
                          onChange={e => setEditDate(e.target.value)}
                          className="form-control border-radius"
                          required

                        />
                      </div>

                      <div className="col-6">
                        <input
                          type="time"
                          value={moment(new Date()).format("hh:mm")}
                          className="form-control border-radius"
                          required

                        />
                      </div>
                    </Row>
                  </div>
                </Row>
              </div>
            </div>
          </Form>
        </div>
      </div>

      {/* Follow Up Modal */}
      <Modal isOpen={followUp} size="md" className="bd-custom-md-3" centered>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Follow Up</h5>
          <button
            type="button"
            onClick={() => {
              setFollowUp(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleAddFollowup}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Date <span className="text-danger">*</span></label>
              <input
                type="date"
                value={folllowUpDate}
                onChange={e => setFollowUpDate(e.target.value)}
                className="form-control border-radius"
                required
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="">Purpose <span className="text-danger">*</span></label>
              <input
                type="text"
                className="form-control border-radius"
                placeholder="Enter Purpose"
                value={followUpDesc}
                onChange={e => setFollowUpDesc(e.target.value)}
                required
              />
            </div>
            <div className="mt-2 text-end">
              <button className="border-radius btn btn-green btn-purple">
                <i className="bx bx-send me-1"></i>
                Submit
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Notes
