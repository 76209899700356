import React, { useEffect, useState, useRef } from "react"
import PropTypes from "prop-types"
//time picker
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import "../../../../assets/css/DietPlan/Calendar.css"
import { isEmpty } from "lodash"
import moment from "moment"
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
//Import Breadcrumb
//css
import "@fullcalendar/bootstrap/main.css"

import * as Yup from "yup"
import { useFormik } from "formik"

import "@fullcalendar/react/dist/vdom"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import axios from "axios"
import { getDecryptedData } from "pages/Config"

import {
  addNewEvent as onAddNewEvent,
  deleteEvent as onDeleteEvent,
  getCategories as onGetCategories,
  // getEvents as onGetEvents,
  updateEvent as onUpdateEvent,
} from "../../../../store/actions"

import DeleteModal from "./DeleteModal"

//redux
import { useSelector, useDispatch } from "react-redux"
import Loader from "pages/Loader"
import {
  MobileTimePicker,
  StaticTimePicker,
  TimePicker,
} from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material"
import swal from "sweetalert"
import { getEventsSuccess } from "store/calendar/actions"
import { ConfigUrl } from "ConfigUrl"
import Breadcrumb from "components/Common/Breadcrumb"

const AddDietPlan1 = props => {
  const clientData = useLocation()
  const params = useParams()
  const [loading, setLoading] = useState(false)
  const calendarRef = useRef(null) // Ref to store FullCalendar component
  const [clientId, setClientId] = useState("")
  const [clientName, setClientName] = useState("")
  const [selectedTime, setSelectedTime] = useState(null)
  const [selectedDate, setSelectedDate] = useState(null)
  const [selectedNote, setSelectedNote] = useState(null)
  const [planId, setPlanId] = useState(null)
  const [dietDescription, setDietDescription] = useState(null)
  const [droppedPosition, setDroppedPosition] = useState(null)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [defaultDate, setDefaultDate] = useState("")

  const [selectedButton, setSelectedButton] = useState({
    id: null,
    title: null,
  })
  const [alertSelectedButton, setAlertSelectedButton] = useState(false)
  const [dropdownStates, setDropdownStates] = useState({})

  useEffect(() => {
    if (clientData.state) {
      setDefaultDate(moment(clientData.state.def_date).format("YYYY-MM-DD"))
      setClientId(clientData.state?.props.id)
      getCalenderData(clientData.state?.props.id)
      setStartDate(clientData.state.startDate)
      setEndDate(clientData.state.endDate)
    }
  }, [])

  useEffect(() => {
    window.scrollTo(2, 2)
  }, [])

  useEffect(() => {
    if (calendarRef.current) {
      // Change the view to week view after the calendar has been initialized
      calendarRef.current.getApi().changeView("dayGridWeek")
    }
  }, [])
  //meta title
  document.title = "Add Diet Plan | Nutri Connect Pro"
  const dispatch = useDispatch()

  const [event, setEvent] = useState({})
  // const [dietEvents, setDietEvents] = useState([])

  // all clients
  const [allClientData, setAllClientData] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate(`${ConfigUrl.appUrl}dashboard`)
    }
  }, [])

  const onDrag = event => {
    event.preventDefault()
    setDroppedPosition({
      x: event.clientX,
      y: event.clientY,
    })
  }

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Diet"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          classNames: event.className,
          // Keep the date the same and only update the time
          start: moment(event.start)
            .set({
              hour: selectedTime ? selectedTime.hour() : moment().hour(),
              minute: selectedTime ? selectedTime.minute() : moment().minute(),
              second: selectedTime ? selectedTime.second() : moment().second(),
            })
            .toDate(),
        }
        dispatch(onUpdateEvent(updateEvent))
        validation.resetForm()
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values.title,
          // Use the selected time or default to current time
          start: selectedTime ? selectedTime.toDate() : moment().toDate(),
          className: event.className || "bg-danger",
        }
        dispatch(onAddNewEvent(newEvent))
        validation.resetForm()
      }

      setSelectedDay(null)
      setSelectedTime(null) // Reset selected time
      toggle()
    },
  })

  // category validation
  const categoryValidation = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: (event && event.title) || "",
      // category: (event && event.category) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Diet"),
      // category: Yup.string().required("Please Enter Your Billing Name"),
    }),
    onSubmit: values => {
      const newEvent = {
        id: Math.floor(Math.random() * 100),
        title: values["title"],
        start: selectedDay ? selectedDay.date : new Date(),
        className: values.event_category
          ? values.event_category + " text-white"
          : "bg-danger text-white",
      }
      // save new event

      dispatch(onAddNewEvent(newEvent))
      toggleCategory()
    },
  })
  const [events2, setEvents2] = useState([])
  const { events } = useSelector(state => ({
    events: state.calendar.events,
  }))

  const [modal, setModal] = useState(false)
  const [editModal, setEditModal] = useState(false)
  const [deleteModal, setDeleteModal] = useState(false)

  const [selectClient, setSelectClient] = useState(false)
  useEffect(() => {
    if (params?.client_id == undefined) {
      if (clientId == "") {
        setSelectClient(true)
      }
    } else {
      setClientId(params?.client_id)
      getCalenderData(params?.client_id)
    }
  }, [])

  const [selectedDay, setSelectedDay] = useState(0)
  const [isEdit, setIsEdit] = useState(false)

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [dispatch])

  useEffect(() => {
    if (!modal && !isEmpty(event) && !!isEdit) {
      setTimeout(() => {
        setEvent({})
        setIsEdit(false)
      }, 500)
    }
  }, [modal, event])

  /**
   * Handling the modal state
   */
  const tottgleDrop = e => {
    setModal(false)
    setEditModal(false)
  }
  const toggle = e => {
    if (e && e.target.classList.contains("bx bx-dots-vertical-rounded")) {
      return // Prevent opening modal if dropdown icon is clicked
    }
    if (modal) {
      setModal(false)
      setEvent(null)
    } else {
      setModal(true)
      setSelectedTime(null)
      setDietDescription("")
      setSelectedNote("")
    }
  }
  const toggleCategory = () => {
    setModal(true)
    setDietDescription("")
    setSelectedNote("")
    setSelectedTime(null)
    setSelectedButton({
      id: null,
      title: "",
    })
  }
  const toggleEditModal = () => {
    setEditModal(!editModal)
  }
  /**
   * Handling date click on calendar
   */
  const handleDateClick = async arg => {
    const confirmDate = () => {
      setSelectedDate(arg.dateStr)

      if (arg.jsEvent.target.classList.contains("fc-event")) {
        const date = arg.date
        const day = date.getDate()
        const month = date.getMonth()
        const year = date.getFullYear()
        const currectDate = new Date()
        const currentHour = currectDate.getHours()
        const currentMin = currectDate.getMinutes()
        const currentSec = currectDate.getSeconds()
        const modifiedDate = new Date(
          year,
          month,
          day,
          currentHour,
          currentMin,
          currentSec
        )
        const modifiedData = { ...arg, date: modifiedDate }

        setSelectedDay(modifiedData)
        setSelectedTime(null) // Reset selected time when a new date is clicked
        toggle()
      } else {
        toggleCategory()
      }
    }

    if (clientId != "") {
      const formData = new FormData()
      formData.append("client_id", clientId)
      formData.append("date", arg.dateStr)
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}check_date`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          confirmDate()
        } else {
          swal({
            text: "Sorry! You are not allowed to add plan on this date.",
            icon: "warning",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      swal({
        text: "Please Select the Client to Add Plan",
        icon: "warning",
      })
    }
  }
  /**
   * Handling click on event on calendar
   */
  const handleEventClick = arg => {
    const event = arg.event
    setPlanId(event?._def.extendedProps?.main_id)
    const title = `${event._def.title}`.replace(/\s/g, "").toLowerCase()
    let id
    switch (title) {
      case "wakingup":
        id = 1
        break
      case "breakfast":
        id = 2
        break
      case "midmeal":
        id = 3
        break
      case "lunch":
        id = 4
        break
      case "eveningsnack":
        id = 5
        break
      case "dinner":
        id = 6
        break
      case "postdinner":
        id = 7
        break

      default:
        id = 0
    }

    setSelectedButton({ id: id, title: title })
    const date = event._instance.range.start
    const final_date = moment(date).format("YYYY-MM-DD")
    const mainTitle = event._def.extendedProps?.main_title
    const Note = event._def.extendedProps?.main_note
    const timeData = event._def.extendedProps?.time_data
    setSelectedNote(Note)
    setSelectedTime(new Date(`${final_date} ${timeData}`))
    setDietDescription(mainTitle)

    setSelectedDate(final_date)
    setEvent({
      id: event.id,
      title: event.title,
      title_category: event.title_category,
      start: event.start,
      className: event.classNames,
      category: event.classNames[0],
      event_category: event.classNames[0],
    })
    setEditModal(true)
  }

  const handleDeleteEvent = () => {
    if (event && event.id) {
      dispatch(onDeleteEvent(event.id))
    }
    setDeleteModal(false)
  }

  const [main_title, setMainTitle] = useState("")
  const onDrop = async event => {
    const confirmDate = () => {
      const title = `${event.draggedEl.lastChild.data}`
        .replace(/\s/g, "")
        .toLowerCase()
      let id
      switch (title) {
        case "wakingup":
          id = 1
          break
        case "breakfast":
          id = 2
          break
        case "midmeal":
          id = 3
          break
        case "lunch":
          id = 4
          break
        case "eveningsnack":
          id = 5
          break
        case "dinner":
          id = 6
          break
        case "postdinner":
          id = 7
          break

        default:
          id = 0
      }
      setSelectedButton({ id: id, title: title })
      const date = event["date"]
      const day = date.getDate()
      const month = date.getMonth()
      const year = date.getFullYear()

      const final_date = moment(date).format("YYYY-MM-DD")
      setSelectedDate(final_date)

      // const currectDate = date
      const currectDate = new Date()
      const currentHour = currectDate.getHours()
      const currentMin = currectDate.getMinutes()
      const currentSec = currectDate.getSeconds()
      const modifiedDate = new Date(
        year,
        month,
        day,
        currentHour,
        currentMin,
        currentSec
      )

      const draggedEl = event.draggedEl
      const draggedElclass = draggedEl.className
      if (
        draggedEl.classList.contains("external-event") &&
        draggedElclass.indexOf("fc-event-draggable") == -1
      ) {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: draggedEl.innerText,
          MainTitle: draggedEl.innerText,
          start: modifiedDate,
          className: draggedEl.className,
        }
        setEvent(newEvent) // Set the dragged event to the state

        setMainTitle(draggedEl.innerText)
        dispatch(onAddNewEvent(newEvent))
        setIsEdit(true)
        toggle()
      }
    }
    if (clientId != "") {
      const formData = new FormData()
      formData.append("client_id", clientId)
      formData.append("date", event.dateStr)
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}check_date`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          confirmDate()
        } else {
          swal({
            text: "Sorry! You are not allowed to add plan on this date.",
            icon: "warning",
          })
        }
      } catch (error) {
        console.log(error)
      }
    } else {
      swal({
        text: "Please Select the Client to Add Plan",
        icon: "warning",
      })
    }
  }

  // Function to handle the click event of plus icon

  const handlePlusIconClick = async erg => {
    setSelectedButton({
      id: erg.event._def.extendedProps.main_id,
      title: erg.event._def.title,
    })
    // setSelectedButton(erg.event._def.title)
    setDietDescription(erg.event._def.extendedProps.main_title)
    setSelectedTime(
      new Date(`${selectedDate} ${erg.event._def.extendedProps.time_data}`)
    )
    setSelectedNote(erg.event._def.extendedProps.main_note)
    setModal(true)
  }

  const confirmdelete = arg => {
    const id = arg._def.extendedProps.main_id
    swal({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      buttons: true,
    }).then(async willDelete => {
      if (willDelete) {
        setLoading(true)
        handleDeleteEvent(arg.event)
        const formData = new FormData()
        formData.append("plan_id", id)
        formData.append("client_id", clientId)
        try {
          const response = await axios.post(
            `${ConfigUrl.apiUrl}delete_dietplan`,
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          if (response) {
            setLoading(false)
            const data2 = response.data.data
            const data = data2?.map((ob, i) => {
              const date = new Date(`${ob.date} ${ob.time_data}`)
              const type = ob.type?.replace(/\s/g, "").toLowerCase()
              return {
                id: ob.id,
                main_id: ob.id,
                main_note: ob.note,
                time_data: ob.time_data,
                title: ob.type,
                // id: Math.floor(Math.random() * 100) + date,
                main_title: ob.description,
                start: date,
                className: `external-event fc-event text-white ${
                  type === "breakfast" ? "fix-button2 " : ""
                }${type === "wakingup" ? "fix-button1 " : ""}
                ${type === "midmeal" ? "fix-button3 " : ""}
                ${type === "lunch" ? "fix-button4 " : ""}
                ${type === "eveningsnack" ? "fix-button5 " : ""}
                ${type === "dinner" ? "fix-button6 " : ""}
                ${type === "postdinner" ? "fix-button7 " : ""}`,
              }
            })
            swal({
              text: "Diet Plan Deleted successfully",
              icon: "success",
            })
            setEditModal(false)
            setEvents2(data)
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      }
    })
  }
  const renderEventContent = arg => {
    const mainTitle = arg?.event._def?.extendedProps.main_title
    const timeData = arg?.event._def?.extendedProps.time_data

    //to trigger only clicked icons dropdown
    const toggleDropdown = eventId => {
      setDropdownStates(prevStates => ({
        ...prevStates,
        [eventId]: !prevStates[eventId],
      }))
    }
    // icons color
    let iconClass = "" // Default class
    let buttonStyle = {} // Default style object

    switch (arg.event.title) {
      case "Waking up":
        iconClass = "icon1" // Set class for "On waking up" event
        break
      case "Breakfast":
        iconClass = "icon2" // Set class for "Breakfast" event
        break
      case "Mid meal":
        iconClass = "icon3" // Set class for "Mid meal" event
        break
      case "Lunch":
        iconClass = "icon4" // Set class for "Mid meal" event
        break
      case "Evening Snack":
        iconClass = "icon5" // Set class for "Mid meal" event
        break
      case "Dinner":
        iconClass = "icon6" // Set class for "Mid meal" event
        break
      case "Post Dinner":
        iconClass = "icon7" // Set class for "Mid meal" event
        break
      case "Long Event":
        iconClass = "icon3" // Set class for "Mid meal" event
        break
      case "Repeating Event":
        buttonStyle = { color: "rgb(101, 101, 255)" }
        break
      case "Meeting":
        iconClass = "icon2"
        break
      case "Brithday Party":
        iconClass = "icon2"
        break
      default:
        iconClass = "" // Default class
        buttonStyle = {}
        break
    }

    return (
      <>
        <div className="div-cd">
          <div className="fc-time">
            <div className="diet-time">{timeData}</div>

            {/* <div>  <b>{main_title}</b></div> */}
            <div className="diet-title"> {arg.event.title}</div>

            <div>
              <Dropdown
                isOpen={dropdownStates[arg.event.id] || false}
                toggle={() => toggleDropdown(arg.event.id)}
              >
                <DropdownToggle
                  tag="i"
                  className="btn nav-btn tripple-dot "
                  type="button"
                  onClick={e => {
                    setModal(false)
                    setEditModal(false)
                    // toggle(e)
                    tottgleDrop(e)
                    // toggleDropdown(arg.event.id)
                  }} // Pass event object to toggle function
                >
                  <i className="bx bx-dots-vertical-rounded text-white" />
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end action-drop">
                  <DropdownItem
                    href="#"
                    className=""
                    onClick={() => {
                      setModal(false)
                      setEditModal(false)
                      handlePlusIconClick(arg)
                    }}
                  >
                    <i
                      className={`bx bx-copy font-size-16  ${iconClass}`}
                      style={buttonStyle}
                    ></i>{" "}
                    Copy
                  </DropdownItem>
                  <DropdownItem
                    href="#"
                    className=""
                    // onClick={() => handleDeleteEvent(arg.event)}
                    onClick={() => {
                      confirmdelete(arg.event)
                      setModal(false)
                      setEditModal(false)
                      // handleDeleteEvent(arg.event)
                      // setDeleteModal(true)
                    }}
                  >
                    <i
                      className={`bx bx-trash font-size-16`}
                      style={{ color: "red" }}
                    ></i>{" "}
                    Delete
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          </div>
          <div className="fc-title" style={{ fontWeight: "600" }}>
            {mainTitle}
          </div>
        </div>
      </>
    )
  }

  // Api integration
  // displaying diet plans in the calendar date-wise.
  const getCalenderData = async client_id => {
    setLoading(true)
    const fatchDates = async () => {
      const formData = new FormData()
      formData.append("client_id", client_id)
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}plan_dates`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response) {
          setStartDate(
            response.data.data.length > 0
              ? response.data.data[response.data.data.length - 1]
              : null
          )
          setEndDate(
            response.data.data.length > 0 ? response.data.data[0] : null
          )
          setSelectClient(false)
        }
      } catch (error) {
        console.log(error)
      }
    }
    fatchDates()
    const formData = new FormData()
    formData.append("client_id", client_id)
    try {
      const response = await axios.post(
        `${ConfigUrl.apiUrl}dietplans`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response) {
        setLoading(false)
        const data2 = response.data?.data
        const data = data2?.map((ob, i) => {
          const date = new Date(`${ob.date} ${ob.time_data}`)
          const type = ob.type?.replace(/\s/g, "").toLowerCase()

          return {
            id: ob.id,
            main_id: ob.id,
            time_data: ob.time_data,
            main_note: ob.note,
            title: ob.type,
            // id: Math.floor(Math.random() * 100) + date,
            main_title: ob.description,
            start: date,
            className: `external-event fc-event text-white ${
              type === "breakfast" ? "fix-button2 " : ""
            }${type === "wakingup" ? "fix-button1 " : ""}
            ${type === "midmeal" ? "fix-button3 " : ""}
            ${type === "lunch" ? "fix-button4 " : ""}
            ${type === "eveningsnack" ? "fix-button5 " : ""}
            ${type === "dinner" ? "fix-button6 " : ""}
            ${type === "postdinner" ? "fix-button7 " : ""}`,
          }
        })
        setEvents2(data)
      }
    } catch (error) {
      setLoading(false)
      console.log(error)
    }
  }

  //  Get clients
  const GetClient = async () => {
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("added_by", localStorage.getItem("adminId"))
      const response = await axios.post(
        `${ConfigUrl.apiUrl}client_listing`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response) {
        setAllClientData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }
  useEffect(() => {
    GetClient()
  }, [])

  // Edit Diet
  const handleEditDiet = async ev => {
    ev.preventDefault()
    setLoading(true)
    const formData = new FormData()
    formData.append("client_id", clientId)
    formData.append("date", selectedDate)
    try {
      const response = await axios.post(
        `${ConfigUrl.apiUrl}check_date`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      if (response.data.success) {
        const time_date = moment(new Date(selectedTime)).format("hh:mm")
        const formDatas = new FormData()
        formDatas.append("time_data", time_date)
        formDatas.append("type", selectedButton?.title)
        formDatas.append("description", dietDescription)
        formDatas.append("type_id", selectedButton.id)
        // formDatas.append("note", selectedNote)
        formDatas.append("date", selectedDate)
        formDatas.append("client_id", clientId)
        formDatas.append("plan_id", planId)
        formDatas.append("added_by", localStorage.getItem("adminId"))
        console.log(formDatas)

        try {
          const response = await axios.post(
            `${ConfigUrl.apiUrl}edit_dietplan`,
            formDatas,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          if (response) {
            setLoading(false)
            const data2 = response.data.data
            const data = data2?.map((ob, i) => {
              const date = new Date(`${ob.date} ${ob.time_data}`)
              const type = ob.type?.replace(/\s/g, "").toLowerCase()
              return {
                id: ob.id,
                main_id: ob.id,
                time_data: ob.time_data,
                main_note: ob.note,
                title: ob.type,
                // id: Math.floor(Math.random() * 100) + date,
                main_title: ob.description,
                start: date,
                className: `external-event fc-event text-white ${
                  type === "breakfast" ? "fix-button2 " : ""
                }${type === "wakingup" ? "fix-button1 " : ""}
                  ${type === "midmeal" ? "fix-button3 " : ""}
                  ${type === "lunch" ? "fix-button4 " : ""}
                  ${type === "eveningsnack" ? "fix-button5 " : ""}
                  ${type === "dinner" ? "fix-button6 " : ""}
                  ${type === "postdinner" ? "fix-button7 " : ""}`,
              }
            })
            setEditModal(false)
            swal({
              text: "Diet Plan Edited successfully",
              icon: "success",
            })
            setEvents2(data)
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      } else {
        setLoading(false)
        console.log("check_date", response)
        swal({
          text: "Sorry! You are not allowed to add plan on this date.",
          icon: "warning",
        }).then(willOk => {
          setModal(false)
        })
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleSubmitDiet = async e => {
    e.preventDefault()
    const confirmDate = async () => {
      setLoading(true)
      e.preventDefault()
      if (clientId !== "") {
        const time_date = moment(new Date(selectedTime)).format("hh:mm")
        const formData = new FormData()
        formData.append("time_data", time_date)
        formData.append("type", selectedButton?.title)
        formData.append("description", dietDescription)
        formData.append("type_id", selectedButton.id)
        formData.append("note", selectedNote)
        formData.append("date", selectedDate)
        formData.append("client_id", clientId)
        formData.append("added_by", localStorage.getItem("adminId"))

        try {
          const response = await axios.post(
            `${ConfigUrl.apiUrl}add_dietplan`,
            formData,
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            }
          )
          if (response) {
            const data2 = response.data.data
            const data = data2?.map((ob, i) => {
              const date = new Date(`${ob.date} ${ob.time_data}`)
              const type = ob.type?.replace(/\s/g, "").toLowerCase()
              return {
                title: ob.type,
                id: ob.id,
                main_id: ob.id,
                time_data: ob.time_data,
                main_note: ob.note,
                main_title: ob.description,
                start: date,
                className: `external-event fc-event text-white ${
                  type === "breakfast" ? "fix-button2 " : ""
                }${type === "wakingup" ? "fix-button1 " : ""}
                ${type === "midmeal" ? "fix-button3 " : ""}
                ${type === "lunch" ? "fix-button4 " : ""}
                ${type === "eveningsnack" ? "fix-button5 " : ""}
                ${type === "dinner" ? "fix-button6 " : ""}
                ${type === "postdinner" ? "fix-button7 " : ""}`,
              }
            })
            setEvents2(data)

            setLoading(false)
            swal({
              text: "Diet Plan added successfully",
              icon: "success",
            })
            setModal(false)
          }
        } catch (error) {
          setLoading(false)
          console.log(error)
        }
      } else {
        setLoading(false)
        swal({
          text: "Please select a client",
          icon: "warning",
        })
      }
    }
    const selectedMeal = async () => {
      const formData = new FormData()
      formData.append("client_id", clientId)
      formData.append("date", selectedDate)
      try {
        const response = await axios.post(
          `${ConfigUrl.apiUrl}check_date`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )
        if (response.data.success) {
          confirmDate()
        } else {
          swal({
            text: "Sorry! You are not allowed to add plan on this date.",
            icon: "warning",
          }).then(willOk => {
            setModal(false)
          })
        }
      } catch (error) {
        console.log(error)
      }
    }
    {
      selectedButton.id === null ? setAlertSelectedButton(true) : selectedMeal()
    }
  }

  function handleDayCellDidMount(arg) {
    const initialDate = new Date(clientData.state?.def_date)
    const cellDate = arg.date

    if (
      cellDate.getFullYear() === initialDate.getFullYear() &&
      cellDate.getMonth() === initialDate.getMonth() &&
      cellDate.getDate() === initialDate.getDate()
    ) {
      arg.el.style.backgroundColor = "#ebf7fb" // Change this to whatever color you prefer
    }
  }

  useEffect(() => {
    const close = (e) => {
      if(e.key === 'Escape' ){
        setSelectClient(false)
      }
    }
    window.addEventListener('keydown', close)
  return () => window.removeEventListener('keydown', close)
},[])

  return (
    <React.Fragment>
      {loading && <Loader />}

      {/* fixed buttons */}
      {/* Render fixed buttons on the left side */}

      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setDeleteModal(false)}
      />
      <Breadcrumb
        title={"Dashboard"}
        breadcrumbItem={`${clientName} ${
          clientName != "" ? "'s" : ""
        } Diet Plan`}
      />
      <div className="page-content position-relative">
        <Container fluid={true}>
          <div className="flex-column fixed-buttons-modified d-flex align">
            <div id="external-events">
              <div
                className="external-event fc-event text-white fix-button1"
                draggable
                onDrag={event => {
                  onDrag(event, category)
                }}
              >
                <i className="bx bxs-sun font-size-18 fix-btn-icon"></i> Waking
                up
              </div>
              <div
                className="external-event fc-event text-white fix-button2"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bxs-coffee font-size-18 fix-btn-icon"></i>{" "}
                Breakfast
              </div>
              <div
                className="external-event fc-event text-white fix-button3"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bx-food-menu font-size-18 fix-btn-icon"></i>{" "}
                Mid meal
              </div>
              <div
                className="external-event  fc-event text-white fix-button4"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bx-dish font-size-18 fix-btn-icon"></i> Lunch
              </div>
              <div
                className="external-event  fc-event text-white fix-button5"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bx-restaurant font-size-18 fix-btn-icon"></i>{" "}
                Evening Snack
              </div>
              <div
                className="external-event fc-event text-white fix-button6"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bx-dish font-size-18 fix-btn-icon"></i> Dinner
              </div>
              <div
                className="external-event fc-event text-white fix-button7"
                draggable
                onDrag={event => onDrag(event, category)}
              >
                <i className="bx bxs-moon font-size-18 fix-btn-icon"></i> Post
                Dinner
              </div>
            </div>
          </div>
          <Row>
            <Col className="col-12 px-0">
              <Row>
                <Col lg={9}>
                  <Card>
                    <CardBody>
                      <div className="">
                        <form className="gap-3 p-3 pb-2 d-flex justify-content-right align-items-center">
                          <div>
                            <h6 className="mb-0 font-size-15 text-purple">
                              Change Client
                            </h6>
                          </div>

                          <div className="d-flex gap-2 col-md-3 ">
                            <select
                              className="border-radius form-select"
                              style={{ width: "100%", fontSize: "13px" }}
                              value={clientId}
                              onChange={e => {
                                setClientId(e.target.value)
                                getCalenderData(e.target.value)
                                setClientName(
                                  getDecryptedData(
                                    allClientData?.find(
                                      obj => obj.id == e.target.value
                                    )?.fullname
                                  )
                                )
                              }}
                            >
                              <option value="" selected>
                                Select Client
                              </option>
                              {allClientData?.map(ob => {
                                return (
                                  <option
                                    value={ob.id}
                                    key={ob.id}
                                    id={getDecryptedData(ob.fullname)}
                                  >
                                    {getDecryptedData(ob.fullname)}
                                  </option>
                                )
                              })}
                            </select>
                          </div>
                        </form>
                      </div>
                      {/* fullcalendar control */}

                      <FullCalendar
                        ref={calendarRef}
                        className="full-width-calendar"
                        plugins={[
                          BootstrapTheme,
                          dayGridPlugin,
                          interactionPlugin,
                        ]}
                        // eventStartEditable={false}
                        // slotDuration={"00:15:00"}
                        handleWindowResize={true}
                        themeSystem="bootstrap"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek",
                        }}
                        events={events2}
                        editable={true}
                        // droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                        eventContent={renderEventContent}
                        validRange={{
                          start: endDate,
                          end: new Date(
                            new Date(startDate).getTime() +
                              0 * 24 * 60 * 60 * 1000
                          ),
                        }}
                        initialDate={clientData.state?.def_date || null}
                        dayCellDidMount={handleDayCellDidMount}
                      />
                      {/* Edit event modal */}

                      <Modal isOpen={editModal} className="" centered>
                        <ModalHeader
                          toggle={toggleEditModal}
                          tag="h5"
                          className="py-3 px-4 border-bottom-0 add-modal-header "
                        >
                          Edit Diet
                        </ModalHeader>
                        <ModalBody className="p-4 add-modal-body">
                          <Form onSubmit={handleEditDiet}>
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Diet Description
                                  </Label>
                                  <Input
                                    name="title"
                                    type="text"
                                    placeholder=""
                                    required
                                    onChange={e =>
                                      setDietDescription(e.target.value)
                                    }
                                    value={dietDescription}
                                  />
                                  {categoryValidation.touched.title &&
                                  categoryValidation.errors.title ? (
                                    <FormFeedback type="invalid">
                                      {categoryValidation.errors.title}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col className="col-12">
                                <Label className="form-label">Time</Label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <StaticTimePicker
                                    orientation="landscape"
                                    value={moment(selectedTime)}
                                    onChange={e => {
                                      setSelectedTime(e?.$d)
                                    }}
                                  />
                                </LocalizationProvider>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="col-6"></Col>
                              <Col className="col-6 text-end">
                                <button
                                  type="button"
                                  className="btn btn-light me-1"
                                  onClick={toggleCategory}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn bg-light-green text-white"
                                  id="btn-save-event"
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                      {/* end */}
                      <Modal isOpen={modal} className="" centered>
                        <ModalHeader
                          toggle={toggle}
                          tag="h5"
                          className="py-3 px-4 add-modal-header text-uppercase"
                        >
                          Add {selectedButton?.title} Diet
                        </ModalHeader>
                        <ModalBody className="p-4 add-modal-body">
                          <Form onSubmit={handleSubmitDiet}>
                            {alertSelectedButton ? (
                              <div className="d-flex justify-content-between py-2 alert alert-danger">
                                <span>Please select any meal</span>
                                <button
                                  type="button"
                                  className="btn p-0 border-0"
                                  onClick={() => setAlertSelectedButton(false)}
                                >
                                  <i className="fas fa-times"></i>
                                </button>
                              </div>
                            ) : null}
                            <Row>
                              <Col className="col-12 d-flex justify-content-center">
                                <div className="modal-btn-main1 w-100 justify-content-center mobile-flex-wrap">
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "wakingup"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 1,
                                        title: "Waking up",
                                      })
                                    }
                                  >
                                    <i className=" bx bxs-sun font-size-16 fix-btn-icon"></i>{" "}
                                    Waking up
                                  </div>
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "breakfast"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 2,
                                        title: "Breakfast",
                                      })
                                    }
                                  >
                                    <i className="bx bxs-coffee font-size-16 fix-btn-icon"></i>{" "}
                                    Breakfast
                                  </div>
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "midmeal"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 3,
                                        title: "Mid meal",
                                      })
                                    }
                                  >
                                    <i className="bx bx-food-menu font-size-16 fix-btn-icon"></i>{" "}
                                    Mid meal
                                  </div>
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "lunch"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 4,
                                        title: "Lunch",
                                      })
                                    }
                                  >
                                    <i className="bx bx-dish font-size-16 fix-btn-icon"></i>{" "}
                                    Lunch
                                  </div>
                                </div>
                              </Col>

                              <Col className="col-12 d-flex justify-content-center">
                                <div className="modal-btn-main2 pb-2 justify-content-center mobile-flex-wrap">
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "eveningsnack"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 5,
                                        title: "Evening Snack",
                                      })
                                    }
                                  >
                                    <i className="bx bx-restaurant font-size-16 fix-btn-icon"></i>{" "}
                                    Evening Snack
                                  </div>
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "dinner"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 6,
                                        title: "Dinner",
                                      })
                                    }
                                  >
                                    <i className="bx bx-dish font-size-16 fix-btn-icon"></i>{" "}
                                    Dinner
                                  </div>
                                  <div
                                    className={`external-event fc-event text-white modal-btn ${
                                      selectedButton?.title
                                        ?.replace(/\s/g, "")
                                        .toLowerCase() === "postdinner"
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() =>
                                      setSelectedButton({
                                        id: 7,
                                        title: "Post Dinner",
                                      })
                                    }
                                  >
                                    <i className="bx bxs-moon font-size-16 fix-btn-icon"></i>{" "}
                                    Post Dinner
                                  </div>
                                </div>
                              </Col>

                              <Col className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">
                                    Diet Description
                                  </Label>
                                  <Input
                                    name="title"
                                    type="text"
                                    placeholder=""
                                    required
                                    onChange={e =>
                                      setDietDescription(e.target.value)
                                    }
                                    value={dietDescription}
                                  />
                                  {categoryValidation.touched.title &&
                                  categoryValidation.errors.title ? (
                                    <FormFeedback type="invalid">
                                      {categoryValidation.errors.title}
                                    </FormFeedback>
                                  ) : null}
                                </div>
                              </Col>

                              <Col className="col-12">
                                <Label className="form-label">Time</Label>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <StaticTimePicker
                                    orientation="landscape"
                                    value={moment(selectedTime)}
                                    onChange={e => {
                                      setSelectedTime(e?.$d)
                                    }}
                                  />
                                </LocalizationProvider>
                              </Col>
                            </Row>

                            <Row className="mt-2">
                              <Col className="col-6"></Col>
                              <Col className="col-6 text-end">
                                <button
                                  type="button"
                                  className="btn btn-light me-1"
                                  onClick={toggleCategory}
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  className="btn bg-light-green text-white"
                                  id="btn-save-event"
                                >
                                  Save
                                </button>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                      {/* Selection Client */}
                      <Modal
                        isOpen={selectClient}
                        className={props.className}
                        centered
                      >
                        <ModalHeader tag="h5" className="add-modal-header">
                          Select Client
                        </ModalHeader>
                        <ModalBody className="p-4 add-modal-body">
                          <Form
                            onSubmit={e => {
                              e.preventDefault()
                            }}
                          >
                            <Row>
                              <Col className="col-12">
                                <div className="mb-3">
                                  <Label className="form-label">Clients</Label>
                                  <select
                                    className="border-radius form-select col-12"
                                    style={{ fontSize: "13px" }}
                                    value={clientId}
                                    onChange={e => {
                                      setClientId(e.target.value)
                                      getCalenderData(e.target.value)
                                      setClientName(
                                        getDecryptedData(
                                          allClientData?.find(
                                            obj => obj.id == e.target.value
                                          )?.fullname
                                        )
                                      )
                                    }}
                                  >
                                    <option value="" selected>
                                      Select Client
                                    </option>
                                    {allClientData?.map(ob => {
                                      return (
                                        <option
                                          value={ob.id}
                                          key={ob.id}
                                          id={getDecryptedData(ob.fullname)}
                                        >
                                          {getDecryptedData(ob.fullname)}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </ModalBody>
                      </Modal>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
AddDietPlan1.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
}
export default AddDietPlan1
