import React, { useEffect, useRef, useState } from "react"
import { Container } from "reactstrap"
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { useNavigate, useParams } from "react-router-dom"
import { Row } from "reactstrap"
import axios from "axios"
import jsPDF from "jspdf"
import html2canvas from "html2canvas"
import Loader from "pages/Loader"
import { Config, getDecryptedData } from "pages/Config"

const ViewInvoice = () => {
  const [InvoiceData, setInvoiceData] = useState({})
  const [serviceDetails, setServiceDetails] = useState([])
  const [inv_structure, setInvStructure] = useState({})
  const [loading, setLoading] = useState(false)

  const navigate = useNavigate()

  useEffect(() => window.scrollTo(0, 0), [])

  const { invoice_id } = useParams()

  document.title = "View Invoice | Nutri Connect Pro"

  const divRef = useRef(null)

  // Getting Invoice detail
  useEffect(() => {
    const GetInvoice = async () => {
      setLoading(true)
      const formData = new FormData()
      formData.append("invoice_id", invoice_id)
      formData.append("added_by", localStorage.getItem("adminId"))
      try {
        await axios
          .post(`${Config.apiUrl}/view_client_invoice`, formData, {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
          .then(response => {
            const oriData = {
              ...response.data.data.invoice_details,
              account_name: getDecryptedData(
                response.data.data.invoice_details.account_name
              ),
              account_no: getDecryptedData(
                response.data.data.invoice_details.account_no
              ),
              address: getDecryptedData(
                response.data.data.invoice_details.address
              ),
              bank_name: getDecryptedData(
                response.data.data.invoice_details.bank_name
              ),
              branch_name: getDecryptedData(
                response.data.data.invoice_details.branch_name
              ),
              ifsc_code: getDecryptedData(
                response.data.data.invoice_details.ifsc_code
              ),
              name: getDecryptedData(response.data.data.invoice_details.name),
              terms: getDecryptedData(response.data.data.invoice_details.terms),
            }

            setInvStructure(oriData)

            const oriData2 = {
              ...response.data.data,
              customer_name: getDecryptedData(response.data.data.customer_name),
              customer_note: getDecryptedData(response.data.data.customer_note),
              discount: getDecryptedData(response.data.data.discount),
              subtotal: getDecryptedData(response.data.data.subtotal),
              terms: getDecryptedData(response.data.data.terms),
              service_details: getDecryptedData(
                response.data.data.service_details
              ),
              invoice_date: getDecryptedData(response.data.data.invoice_date),
              duedate: getDecryptedData(response.data.data.duedate),
              startdate: getDecryptedData(response.data.data.startdate),
              enddate: getDecryptedData(response.data.data.enddate),
              total: Number(getDecryptedData(response.data.data.total)),
              unpaid_amount: Number(
                getDecryptedData(response.data.data.unpaid_amount)
              ),
            }
            setInvoiceData(oriData2)
            setServiceDetails(JSON.parse(oriData2.service_details))
            setLoading(false)
          })
      } catch (err) {
        swal("Something went wrong", "", "error")
        console.log("object")
        setLoading(false)
      }
    }
    GetInvoice()
  }, [])

  const handleSavePDF = () => {
    const barcodesDiv = divRef.current

    html2canvas(barcodesDiv).then(canvas => {
      const divWidth = barcodesDiv.offsetWidth
      const divHeight = barcodesDiv.offsetHeight
      const ratio = divWidth / divHeight

      const pdfWidth = 210 // A4 page width in mm
      const pdfHeight = pdfWidth / ratio
      const orientation = "p" // Landscape or portrait

      // Create a new jsPDF instance
      const pdf = new jsPDF(orientation, "mm", "A4")

      // Add the canvas to the PDF document
      pdf.addImage(
        canvas.toDataURL("image/jpeg"),
        "JPEG",
        0,
        0,
        pdfWidth,
        pdfHeight
      )

      pdf.save("INVOICE.pdf")
    })
  }

  function convertToIndianCurrency(amount) {
    const ones = [
      "",
      "One",
      "Two",
      "Three",
      "Four",
      "Five",
      "Six",
      "Seven",
      "Eight",
      "Nine",
    ]
    const teens = [
      "Ten",
      "Eleven",
      "Twelve",
      "Thirteen",
      "Fourteen",
      "Fifteen",
      "Sixteen",
      "Seventeen",
      "Eighteen",
      "Nineteen",
    ]
    const tens = [
      "",
      "",
      "Twenty",
      "Thirty",
      "Forty",
      "Fifty",
      "Sixty",
      "Seventy",
      "Eighty",
      "Ninety",
    ]
    const thousands = ["", "Thousand", "Lakh", "Crore"]

    let paisa = Math.floor((amount % 1) * 100)
    let rupees = Math.floor(amount)

    return `${convertToWords(rupees)} Rupees ${
      paisa > 0 ? `${convertToWords(paisa)} Paise` : "Only"
    }`

    function convertToWords(number) {
      let words = ""
      let i = 0
      while (number > 0) {
        let chunk = number % 1000
        number = Math.floor(number / 1000)
        words =
          `${helper(chunk)} ${thousands[i]}${number > 0 ? " " : ""}` + words
        i++
      }
      return words.trim()

      function helper(number) {
        if (number > 99) {
          return `${ones[Math.floor(number / 100)]} Hundred${
            number % 100 > 0 ? " " : ""
          }`
        }
        if (number > 19) {
          return `${tens[Math.floor(number / 10)]}${number % 10 > 0 ? " " : ""}`
        }
        if (number > 9) {
          return teens[number - 10]
        }
        return ones[number]
      }
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Breadcrumbs title={"Dashboard"} breadcrumbItem={"New Invoice"} />
      <div className="page-content mb-3">
        <Container fluid>
          <Row className="px-1 justify-content-center mb-5">
            <div className="col-10 text-end mb-4">
              <button
                onClick={e => {
                  e.preventDefault()
                  navigate(-1)
                }}
                className="border-radius btn ms-md-2 btn-dark back-btn"
              >
                <i className="fas fa-arrow-left me-3"></i>Back
              </button>

              <button
                onClick={handleSavePDF}
                className="btn btn-purple bg-purple border-radius ms-3"
              >
                <i className="fas fa-download me-2"></i>
                Download Invoice
              </button>
            </div>

            <div className="col-md-10">
              <div className="invoice-page bg-white ">
                <div ref={divRef} className="p-4 pb-5">
                  <div className="row justify-content-between">
                    <div className="col-md-4">
                      <h2 className="fw-bold">{inv_structure?.name}</h2>
                    </div>

                    <div className="col-md-5 text-end">
                      {inv_structure?.address && (
                        <>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line1}
                          </p>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line2}
                          </p>
                          <p className="mb-0">
                            {JSON.parse(inv_structure?.address).line3}
                          </p>
                          {/* <p>GST : </p> */}
                        </>
                      )}
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-4 pe-0">
                      <div className="invoice-line"></div>
                    </div>
                    <div className="col-4">
                      <p className="fs-4 py-3 text-center bg-white mb-0">
                        INVOICE
                      </p>
                    </div>
                    <div className="col-4 ps-0">
                      <div className="invoice-line"></div>
                    </div>
                  </div>

                  <div className="row justify-content-between mobile-flex">
                    <div className="col-md-3 ">
                      <p className="mb-1">{InvoiceData?.customer_name},</p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.address)}
                      </p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.city) +
                          ", " +
                          getDecryptedData(InvoiceData?.client?.state)}
                      </p>
                      <p className="mb-1">
                        {getDecryptedData(InvoiceData?.client?.country)},
                      </p>
                    </div>
                    <div className="col-md-5 ps-0">
                      <table className="table ">
                        <tbody>
                          <tr>
                            <th className="border border-secondary py-1">
                              Invoice#
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.invoice_number}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Invoice Date
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.invoice_date}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Terms
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.terms}
                            </td>
                          </tr>
                          <tr>
                            <th className="border border-secondary py-1">
                              Due Date
                            </th>
                            <td className="border border-secondary py-1">
                              {InvoiceData?.duedate}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="mt-4">
                    <table className="table responsive-table responsive ncp-table ">
                      <thead className="table-green">
                        <tr>
                          <th className="border border-secondary" >ID</th>
                          <th className="border border-secondary">
                            Package & Description
                          </th>
                          <th className="border border-secondary">Qty</th>
                          <th className="border border-secondary">Rate</th>
                          <th className="border border-secondary">Tax (%)</th>
                          <th className="border border-secondary">Amount</th>
                        </tr>
                      </thead>

                      <tbody>
                        {serviceDetails?.map((sdata, i) => {
                          return (
                            <tr key={i}>
                              <td className="border border-secondary" data-label="ID">
                                {i + 1}
                              </td>
                              <td className="border border-secondary" data-label="Package & Description">
                                {sdata.service}
                              </td>
                              <td className="border border-secondary" data-label="Qty">
                                {sdata.quantity}
                              </td>
                              <td className="border border-secondary" data-label="Rate">
                                ₹ {sdata.rate}
                              </td>
                              <td className="border border-secondary" data-label="Tax">
                                {sdata.tax}
                              </td>
                              <td className="border border-secondary" data-label="Amount">
                                ₹ {sdata.amount}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="row justify-space-between">
                    <div className="col-md-6 mt-2">
                      <span className="text-dark ">
                        Thank you for giving us an opportunity! Hoping work from
                        you in future
                      </span>
                    </div>
                    <div className="col-md-5">
                      <div className="d-flex py-2 justify-content-between">
                        <span>Sub Total</span>
                        <span>₹ {InvoiceData?.subtotal}</span>
                      </div>
                      <div className="d-flex py-2 justify-content-between">
                        <span>Discount (%)</span>
                        <span>
                          {InvoiceData?.discount &&
                            InvoiceData?.discount != "undefined" &&
                            InvoiceData?.discount}
                          %
                        </span>
                      </div>
                      <div className="d-flex pt-2 border-top border-secondary pb-2 justify-content-between">
                        <strong> Total (₹)</strong>
                        <span>₹ {Number(InvoiceData?.total)?.toFixed(2)}</span>
                      </div>
                    </div>
                  </div>

                  <div className="mt-4 row pb-5 ">
                    <p className="font-size-15">
                      Total in Words :{" "}
                      {convertToIndianCurrency(
                        Number(InvoiceData?.total)?.toFixed(2)
                      ) + " Only"}
                    </p>

                    <h5 className="fw-bold text-dark">Bank Details</h5>
                    <div className="col-md-5">
                      <table className="table">
                        <tr>
                          <th className="bg-transparent text-dark">
                            Bank Name :{" "}
                          </th>
                          <td>{inv_structure?.bank_name}</td>
                        </tr>
                        <tr>
                          <th className="bg-transparent text-dark">
                            Branch Name :
                          </th>
                          <td>{inv_structure?.branch_name} </td>
                        </tr>
                        <tr>
                          <th className="bg-transparent text-dark">
                            Account Name :
                          </th>
                          <td>{inv_structure?.account_name}</td>
                        </tr>
                        <tr>
                          <th className="bg-transparent text-dark">
                            Account No. :
                          </th>
                          <td>{inv_structure?.account_no}</td>
                        </tr>
                        <tr>
                          <th className="bg-transparent text-dark">
                            IFSC Code :{" "}
                          </th>
                          <td> {inv_structure?.ifsc_code} </td>
                        </tr>
                      </table>
                    </div>

                    <div className="mt-4">
                      <h5 className="fw-bold text-dark">Terms &amp; Conditions</h5>
                      <div
                        className="terms "
                        dangerouslySetInnerHTML={{
                          __html: inv_structure?.terms,
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ViewInvoice
