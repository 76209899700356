import React, { useState, useEffect } from "react"
import { Col, Container, Row } from "reactstrap"
import { Card } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import swal from "sweetalert"
import Loader from "pages/Loader"
import axios from "axios"
import { getDecryptedData, getEncryptedData } from "pages/Config"
import { ConfigUrl } from "ConfigUrl"

const Settings = () => {
  const [logo, setLogo] = useState("") // Set the initial value for the logo URL
  const [logoLink, setLogoLink] = useState("")
  const [company_name, setCompanyName] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone, setPhone] = useState("")
  const [gst, setGst] = useState("")
  const [copyright, setCopyright] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")

  const [loading, setLoading] = useState(false)

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])
  const [show_pass, setShowPass] = useState(true)
  const navigate = useNavigate()

  const handleFormSubmit = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("file", logo)
      formData.append("company_name", getEncryptedData(company_name))
      formData.append("email", email)
      formData.append("password", password)
      formData.append("phone", getEncryptedData(phone))
      formData.append("gst", getEncryptedData(gst))
      formData.append("copyright", getEncryptedData(copyright))
      formData.append("state", state)
      formData.append("city", city)
      formData.append("address", getEncryptedData(address))
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${ConfigUrl.apiUrl}add_company`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (response) {
        setLoading(false)
        setLogoLink(response.data[0].logo)
        if (
          response.data[0].company_name != "null" &&
          response.data[0].company_name != "undefined"
        )
          setCompanyName(getDecryptedData(response.data[0].company_name))
        setEmail(response.data[0].email)
        // setPassword(response.data.data.password)
        if (
          response.data[0].phone != "null" &&
          response.data[0].phone != "undefined"
        )
          setPhone(getDecryptedData(response.data[0].phone))
        if (
          response.data[0].gst != "null" &&
          response.data[0].gst != "undefined"
        )
          setGst(getDecryptedData(response.data[0].gst))

        if (
          response.data[0].copyright != "null" &&
          response.data[0].copyright != "undefined"
        )
          setCopyright(getDecryptedData(response.data[0].copyright))
        setState(response.data[0].state)
        setCity(response.data[0].city)
        setAddress(getDecryptedData(response.data[0].address))
        // }

        swal("", "Setting Details Saved successfully!", "success")
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "error")
    }
  }

  useEffect(() => {
    setLoading(true)
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))
    axios
      .post(`${ConfigUrl.apiUrl}view_company`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        console.log("setting ", response.data)

        if (response.data.success == true) {
          setLogoLink(response.data.data.logo)
          // console.log(response.data.data)
          if (
            response.data.data.company_name != "null" &&
            response.data.data.company_name != "undefined"
          )
            setCompanyName(getDecryptedData(response.data.data.company_name))
          setEmail(response.data.data.email)
          // setPassword(response.data.data.password)
          if (
            response.data.data.phone != "null" &&
            response.data.data.phone != "undefined"
          )
            setPhone(getDecryptedData(response.data.data.phone))
          if (
            response.data.data.gst != "null" &&
            response.data.data.gst != "undefined"
          )
            setGst(getDecryptedData(response.data.data.gst))

          if (
            response.data.data.copyright != "null" &&
            response.data.data.copyright != "undefined"
          )
            setCopyright(getDecryptedData(response.data.data.copyright))
          setState(response.data.data.state)

          // getting cities
          const formData2 = new FormData()
          formData2.append("state_id", response.data.data.state)

          axios
            .post(`${ConfigUrl.apiUrl}state_city`, formData2, {
              headers: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
            .then(res => {
              setCities(res.data.data)
            })
            .catch(err => console.log(err))

          setCity(response.data.data.city)
          setAddress(getDecryptedData(response.data.data.address))
        }

        setLoading(false)
      })
      .catch(err => {
        console.log(err)
        setLoading(false)
      })
  }, [])

  // get all countries name
  useEffect(() => {

    const formData = new FormData()
    formData.append("country_id", 1)

    axios
      .post(`${ConfigUrl.apiUrl}country_state`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setStates(response.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  // get all cities name
  const getCitiesList = e => {
    setState(e.target.value)

    const formData = new FormData()
    formData.append("state_id", e.target.value)

    axios
      .post(`${ConfigUrl.apiUrl}state_city`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setCities(response.data.data)
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Row className="mx-0">
        <form onSubmit={handleFormSubmit} className=" px-0">
          <Card>
            <CardBody>
              <Row className="pb-2">
                <div className="col-md-6">
                  <h4 className="fw-bold">General Details</h4>
                </div>
                <div className="col-6 mb-4 text-end">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-dark border-radius me-3 back-btn"
                  >
                    <i className="fas fa-arrow-left me-3"></i>
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-purple border-radius"
                  >
                    {" "}
                    <i className="bx bx-send me-1"></i> Submit{" "}
                  </button>
                </div>

                <div className="col-md-4 d-flex align-items-end">
                  <div>
                    <label htmlFor="" className="fs-5">
                      Upload Logo
                    </label>
                    <input
                      type="file"
                      onChange={e => setLogo(e.target.files[0])}
                      className="form-field py-2"
                    />
                  </div>

                  <div className="ms-4">
                    <img
                      src={logoLink}
                      alt=""
                      style={{
                        width: "60px",
                        height: "54px",
                        objectFit: "contain",
                      }}
                    />

                    {/* <img src={logo} alt="" width={60} /> */}
                  </div>
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="fs-5">
                    Company Name
                  </label>
                  <input
                    type="text"
                    value={company_name}
                    onChange={e => setCompanyName(e.target.value)}
                    placeholder="Enter Company Name"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4">
                  <label htmlFor="" className="fs-5">
                    Phone Number
                  </label>
                  <input
                    type="text"
                    value={phone}
                    onChange={e => setPhone(e.target.value)}
                    placeholder="Enter Phone Number"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Email Address
                  </label>
                  <input
                    type="text"
                    value={email}
                    onChange={e => setEmail(e.target.value)}
                    placeholder="Enter Email Address"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Password{" "}
                    <span className="text-danger">
                      (only if you need to reset)
                    </span>{" "}
                  </label>
                  <input
                    type={show_pass ? "password" : "text"}
                    value={password}
                    onChange={e => setPassword(e.target.value)}
                    placeholder="Enter Password"
                    className="form-field"
                  />
                  <div
                    className="bd-eye-pass pe-3"
                    onClick={() => setShowPass(!show_pass)}
                  >
                    <i
                      className={show_pass ? "fa fa-eye" : "fa fa-eye-slash"}
                    ></i>
                  </div>
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    GST No.
                  </label>
                  <input
                    type="text"
                    value={gst}
                    onChange={e => setGst(e.target.value)}
                    placeholder="Enter GST No"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Copyrights
                  </label>
                  <input
                    type="text"
                    value={copyright}
                    onChange={e => setCopyright(e.target.value)}
                    placeholder="Enter Copyrights"
                    className="form-field"
                  />
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="pb-2">
                <h4 className="fw-bold">Address Details</h4>

                {/* <div className="col-md-4 mt-4">
                                            <label htmlFor="" className="fs-5">Country</label>

                                            <select onChange={getStatesList} className="form-field" required>
                                                <option value=""> Nothing Selected </option>
                                                {
                                                    countries.map((country) => {
                                                        return (
                                                            <option key={country.id}
                                                                name={country.name}
                                                                value={country.id}>
                                                                {country.name} </option>
                                                        )
                                                    })
                                                }
                                            </select>
                                        </div> */}

                <div className="col-md-3 mt-4">
                  <label htmlFor="" className="fs-5">
                    State
                  </label>

                  <select
                    name="states"
                    onChange={getCitiesList}
                    value={state}
                    className="form-field"
                    required
                  >
                    <option value=""> Nothing Selected </option>
                    {states.map(state => {
                      return (
                        <option key={state.id} value={state.id}>
                          {state.name}{" "}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className="col-md-3 mt-4">
                  <label htmlFor="" className="fs-5">
                    City
                  </label>
                  <select
                    name="cities"
                    onChange={e => setCity(e.target.value)}
                    value={city}
                    className="form-field"
                    required
                  >
                    <option value=""> Nothing Selected </option>
                    {cities.map(city => {
                      return (
                        <option key={city.id} value={city.id}>
                          {city.name}{" "}
                        </option>
                      )
                    })}
                  </select>
                </div>

                <div className="col-6 mt-4">
                  <label htmlFor="" className="fs-5">
                    Address
                  </label>
                  <input
                    type="text"
                    value={address}
                    onChange={e => setAddress(e.target.value)}
                    placeholder="Enter Address"
                    className="form-field"
                  />
                </div>

                {/* <div className="col-12 mt-4">
                                            <label htmlFor="" className="fs-5">Please give additional detail about your diet here: </label>
                                            <textarea type="text" rows={4} placeholder="Enter Here" className="form-field" />
                                        </div> */}
              </Row>
            </CardBody>
          </Card>
        </form>
      </Row>
    </React.Fragment>
  )
}

export default Settings
