import React, { useState, useEffect } from "react"
import { Col, Container, Row, Table } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

// Import Components
import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"

//i18n
import { withTranslation } from "react-i18next"
import axios from "axios"
import Loader from "pages/Loader"
import AllInvoices from "./AllInvoices"
import AllFollowUps from "./AllFollowUps"
import { Config, getDecryptedData } from "pages/Config"

const ClientDashboard = props => {
  const [allCounts, setAllCounts] = useState({})
  const [loading, setLoading] = useState({})
  const [allInvoices, setAllInvoices] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    if (localStorage.getItem("sAdminUser") && localStorage.getItem("superId")) {
      navigate("/dashboard")
    }
  }, [])

  
  useEffect(() => {
    setLoading(true)
    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${Config.apiUrl}/dashboard_admin`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        // console.log(response.data)
        setAllCounts(response.data.data)
        setLoading(false)
      })
      .catch(err => console.log(err))
  }, [])

  // getting all invoices
  useEffect(() => {
    setLoading(true)

    const formData = new FormData()

    formData.append("added_by", localStorage.getItem("adminId"))

    axios
      .post(`${Config.apiUrl}/getinvoices`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setLoading(false)
        setAllInvoices(response.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  //meta title
  document.title = "Dashboard | Nutri Connect Pro "

  const reports = [
    {
      title: "All Clients",
      iconClass: "fas fa-users",
      description: allCounts?.all_clients,
      color: "purple",
      link: `${Config.appUrl}/all-clients`,
      textColor: "rgb(32, 91, 94)",
      background: "#d5f5f6",
    },
    {
      title: "Active Clients",
      iconClass: "fas fa-check-circle ",
      description: allCounts?.active_clients,
      color: "cyan",
      link: `${Config.appUrl}/active-clients`,
      textColor: "#3b2d8e",
      background: "#e6e6fa",
    },
    {
      title: "Deactivate Clients",
      iconClass: "bx bx-purchase-tag-alt",
      description: allCounts?.deactive_clients,
      color: "pink",
      link: `${Config.appUrl}/admin-dashboard`,
      textColor: "rgb(115, 17, 115)",
      background: "#eed1c1",
    },
    {
      title: "Invoices",
      iconClass: "fas fa-book fs-4",
      description: allCounts?.invoices,
      color: "yellow",
      link: `${Config.appUrl}/invoices`,
      textColor: "#0b3053",
      background: "#d5f5f6",
    },
  ]

  return (
    <React.Fragment>
      {loading && <Loader />}
      <Breadcrumbs
        title={props.t("Dashboard")}
        breadcrumbItem={props.t("Dashboard")}
      />
      <div className="page-content">
        <Container fluid>
          <Row className="px-md-0 px-3 mb-5">
            <Col
              xl={12}
              className="mb-4 px-0 d-md-flex justify-content-between align-items-center"
            >
              <div className="d-flex align-items-center"></div>
              <div className="mt-md-0 mt-4 text-">
                <Link
                  to={`${Config.appUrl}/add-client`}
                  className="btn btn-green btn-cyan border-radius px-4"
                >
                  <i className="fas fa-plus me-2"></i> Add New Client
                </Link>
              </div>
            </Col>

            <Col xl={12} className=" px-0 mt-1">
              <Row>
                {reports.map((report, key) => (
                  <Col md="3" key={"_col_" + key}>
                    <Card className="mini-stats-wid">
                      <CardBody
                        className="shadow-card card-stats"
                        style={{
                          background: report.background,
                          borderBottom: `3px solid ${report.textColor}`,
                          borderRadius: "5px",
                        }}
                      >
                        <Link to={report.link} className="text-dark">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="text-muted fw-medium fs-5">
                              {report.title}
                            </p>
                            <div className="   align-self-center mini-stat-icon mb-2">
                              <span
                                className={`avatar-title rounded-circle icon-card`}
                              >
                                <i
                                  className={report.iconClass + " fs-5"}
                                  style={{ color: report.textColor }}
                                ></i>
                              </span>
                            </div>
                          </div>
                          <div className="flex-grow-1 ">
                            <h3 className="mb-0">{report.description}</h3>
                          </div>
                        </Link>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
              </Row>

              <Row className="mt-4">
                <div className="col-md-5">
                  <Card className="mini-stats-wid">
                    <CardBody className="shadow-card color-card followup">
                      <AllFollowUps />
                    </CardBody>
                  </Card>
                </div>
                <div className="col-md-7">
                  <AllInvoices />
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withTranslation()(ClientDashboard)
