import PropTypes from "prop-types"
import React, { useState } from "react"

import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
} from "reactstrap"

import { Link, Navigate, useNavigate } from "react-router-dom"
import withRouter from "components/Common/withRouter"

// actions
import { loginUser, socialLogin } from "../../store/actions"

// import images
import profile from "assets/images/profile-img.png"

// import css
import "../../assets/css/Login/Login.css"
import axios from "axios"
import swal from "sweetalert"
import { ConfigUrl } from "ConfigUrl"

const Login = props => {
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState(false)
  const [show_pass, setShowPass] = useState(true)

  const [loading, setLoading] = useState(false)

  //meta title
  document.title = "Login | Nutri Connect Pro"

  const navigate = useNavigate()

  const systemLogin = async e => {
    e.preventDefault()
    setLoading(true)
    try {
      const formData = new FormData()
      formData.append("username", email)
      formData.append("password", password)
      const res = await axios.post(`${ConfigUrl.apiUrl}login`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      if (res.data.message == "success" && res.data.data.length > 0) {
        if (res.data.data[0].role == 0) {
          navigate(`${ConfigUrl.appUrl}dashboard`)
          localStorage.setItem("sAdminUser", res.data.data[0].firstname)
          localStorage.setItem("superId", res.data.data[0].id)
        } else if (res.data.data[0].role == 1) {
          if (res.data.data[0].is_active == 1) {
            navigate(`${ConfigUrl.appUrl}admin-dashboard`)
            localStorage.setItem("adminUser", res.data.data[0].firstname)
            localStorage.setItem("adminId", res.data.data[0].id)
            localStorage.setItem("created_at", res.data.data[0].created_at)
          } else {
            setError(true)
            setErrorMsg("This user is not yet verified by a superadmin")
          }
        }
        setLoading(false)
      } else {
        setError(true)
        setErrorMsg(
          "Username and password are invalid. Please enter correct username and password"
        )
        setLoading(false)
      }
    } catch (error) {
      console.log(error)
      setError(true)
      setErrorMsg(
        "Username and password are invalid. Please enter correct username and password"
      )
      setLoading(false)
    }
  }
  const [flipped, setFlipped] = useState(true)

  return (
    <React.Fragment>
      <div className="login-page">
        <div className="account-pages login-bg">
          <Container>
            <div className={`inner-card ${flipped == false ? "flip" : ""}`}>
              <Row className="justify-content-center">
                <Col md={8} lg={6} xl={5}>
                  {flipped ? (
                    <Card
                      className="overflow-hidden "
                      style={{
                        boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.39) ",
                      }}
                    >
                      <div className="bg-light-purple">
                        <Row>
                          <Col xs={8}>
                            <div className="text-dark p-4">
                              <h5 className="text-purple fw-bold fs-4">
                                Welcome Back !
                              </h5>
                              <p>Sign in to continue to Nutri Connect Pro.</p>
                            </div>
                          </Col>
                          <Col className="col-4 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div>
                          <Link
                            to={`${ConfigUrl.appUrl}`}
                            className="logo-light-element"
                          >
                            <div className="avatar-md profile-user-wid mb-4">
                              <span className="avatar-title rounded-circle bg-light"></span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Form
                            className="form-horizontal"
                            onSubmit={systemLogin}
                          >
                            {error ? (
                              <Alert color="danger">{errorMsg}</Alert>
                            ) : null}

                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                value={email}
                                onChange={e => {
                                  setError(false)
                                  setEmail(e.target.value)
                                }}
                              />
                            </div>

                            <div className="mb-3 position-relative">
                              <Label className="form-label">Password</Label>
                              <Input
                                name="password"
                                type={show_pass ? "password" : "text"}
                                placeholder="Enter Password"
                                value={password}
                                onChange={e => {
                                  setError(false)
                                  setPassword(e.target.value)
                                }}
                              />

                              <div
                                className="bd-eye-pass"
                                onClick={() => setShowPass(!show_pass)}
                              >
                                <i
                                  className={
                                    show_pass ? "fa fa-eye" : "fa fa-eye-slash"
                                  }
                                ></i>
                              </div>
                            </div>

                            <div className="form-check">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                id="customControlInline"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="customControlInline"
                              >
                                Remember me
                              </label>
                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn fw-bold text-purple btn-green bg-light-purple btn-block"
                                type="submit"
                              >
                                {loading && (
                                  <i className="bx bx-hourglass bx-spin align-middle me-2"></i>
                                )}
                                {loading ? "Logging In.." : "Log In"}
                              </button>
                            </div>
                            <div className="mt-3 login-issue">
                              <p className="font-size-12 text-dark">
                              Encountering Issues with Your Login ?{" "}
                                <a
                                  className="text-purple fw-bold"
                                  onClick={() => setFlipped(!flipped)}
                                >
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  ) : (
                    <Card
                      className="overflow-hidden back-card"
                      style={{
                        boxShadow: "0 0.75rem 1.5rem rgba(18, 38, 63, 0.39) ",
                      }}
                    >
                      <div className="bg-light-purple">
                        <Row>
                          <Col xs={8}>
                            <div className="text-dark p-4">
                              <h5 className="text-purple fw-bold fs-4">
                                We're Here to Help
                              </h5>
                              <p>Contact Us for Any Questions or Issues</p>
                            </div>
                          </Col>
                          <Col className="col-4 align-self-end">
                            <img src={profile} alt="" className="img-fluid" />
                          </Col>
                        </Row>
                      </div>
                      <CardBody className="pt-0">
                        <div>
                          <Link
                            to={`${ConfigUrl.appUrl}`}
                            className="logo-light-element"
                          >
                            <div className="avatar-md profile-user-wid mb-4">
                              <span className="avatar-title rounded-circle bg-light"></span>
                            </div>
                          </Link>
                        </div>
                        <div className="p-2">
                          <Form className="form-horizontal row">
                            <div className="mb-3 col-md-12">
                              <Label className="form-label">Name</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter Your Name"
                                type="text"
                              />
                            </div>
                            <div className="mb-3 position-relative col-md-12">
                              <Label className="form-label">
                                Email Address
                              </Label>
                              <Input
                                name="password"
                                type={"email"}
                                placeholder="Enter Your Email Address"
                              />
                            </div>
                            <div className="mb-3 position-relative col-md-12">
                              <Label className="form-label">Message</Label>
                              <textarea
                                name="password"
                                type={"text"}
                                placeholder="Enter Your message Here"
                                className="form-control"
                              />

                            </div>

                            <div className="mt-3 d-grid">
                              <button
                                className="btn fw-bold text-purple btn-green bg-light-purple btn-block"
                                type="submit"
                              >
                                Contact Us
                              </button>
                            </div>
                            <div className="mt-3 login-issue">
                              <p className="font-size-12 text-dark">
                                Go Back to Login ?{" "}
                                <a
                                  className="text-purple fw-bold"
                                  onClick={() => setFlipped(!flipped)}
                                >
                                  Click Here
                                </a>
                              </p>
                            </div>
                          </Form>
                        </div>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
