import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard (super admin)
import Dashboard from "../pages/Dashboard/index"
import AddDieticians from "pages/Dashboard/Components/AddDieticians"
import ViewDieticians from "pages/Dashboard/Components/ViewDieticians"

// client dashboard (Admin)
import ClientDashboard from "pages/ClientDashboard"
import AddClient from "pages/ClientDashboard/Components/AddClient/AddClient"
import AllClients from "pages/ClientDashboard/Components/AddClient/AllClients"
import EditClient from "pages/ClientDashboard/Components/EditClient/EditClient"
import AddDietPlan1 from "pages/ClientDashboard/Components/AddClient/AddDietPlan1"
import Invoices from "pages/ClientDashboard/Components/Invoices/Invoices"
import Packages from "pages/ClientDashboard/Components/Packages/Packages"
import AddInvoice from "pages/ClientDashboard/Components/Invoices/AddInvoice"
import ViewInvoice from "pages/ClientDashboard/Components/Invoices/ViewInvoice"
import EditClientInvoice from "pages/ClientDashboard/Components/EditClient/EditInvoice"
import ResetPassword from "pages/Dashboard/Components/ResetPassword"
import SettingIndex from "pages/Settings/SettingIndex"
import EditDietician from "pages/Dashboard/Components/EditDietician"
import ActiveClient from "pages/ClientDashboard/Components/AddClient/ActiveClient"
import DeactivateClients from "pages/ClientDashboard/Components/AddClient/DeactivateClients"
import { ConfigUrl } from "ConfigUrl"
import InvoiceIndex from "pages/Settings/Invoice"

const authProtectedRoutes = [
  // super admin
  { path: `${ConfigUrl.appUrl}dashboard`, component: <Dashboard /> },
  { path: `${ConfigUrl.appUrl}add-dietician`, component: <AddDieticians /> },
  {
    path: `${ConfigUrl.appUrl}edit-dietician/:diet_id`,
    component: <EditDietician />,
  },
  {
    path: `${ConfigUrl.appUrl}view-dietician`,
    component: <ViewDieticians />,
  },
  { path: `${ConfigUrl.appUrl}reset-password`, component: <ResetPassword /> },

  // admin
  {
    path: `${ConfigUrl.appUrl}admin-dashboard`,
    component: <ClientDashboard />,
  },
  { path: `${ConfigUrl.appUrl}all-clients`, component: <AllClients /> },
  { path: `${ConfigUrl.appUrl}active-clients`, component: <ActiveClient /> },
  {
    path: `${ConfigUrl.appUrl}deactivate-clients`,
    component: <DeactivateClients />,
  },
  { path: `${ConfigUrl.appUrl}add-client`, component: <AddClient /> },

  { path: `${ConfigUrl.appUrl}add-diet-plan`, component: <AddDietPlan1 /> },
  {
    path: `${ConfigUrl.appUrl}add-diet-plan/:client_id`,
    component: <AddDietPlan1 />,
  },
  {
    path: `${ConfigUrl.appUrl}add-invoice/:client_id`,
    component: <AddInvoice />,
  },
  {
    path: `${ConfigUrl.appUrl}edit-invoice/:invoice_id`,
    component: <EditClientInvoice />,
  },

  {
    path: `${ConfigUrl.appUrl}edit-client/:client_id`,
    component: <EditClient />,
  },

  { path: `${ConfigUrl.appUrl}invoices`, component: <Invoices /> },
  { path: `${ConfigUrl.appUrl}add-invoice`, component: <AddInvoice /> },
  {
    path: `${ConfigUrl.appUrl}view-invoice/:invoice_id`,
    component: <ViewInvoice />,
  },

  { path: `${ConfigUrl.appUrl}packages`, component: <Packages /> },

  // setting page
  { path: `${ConfigUrl.appUrl}general-settings`, component: <SettingIndex /> },
  { path: `${ConfigUrl.appUrl}invoice-settings`, component: <InvoiceIndex /> },

  // //profile
  { path: `${ConfigUrl.appUrl}profile`, component: <UserProfile /> },

  {
    path: `${ConfigUrl.appUrl}`,
    exact: true,
    component: <Navigate to={`${ConfigUrl.appUrl}dashboard`} />,
  },
]

const publicRoutes = [
  { path: `${ConfigUrl.appUrl}logout`, component: <Logout /> },
  { path: `${ConfigUrl.appUrl}login`, component: <Login /> },
  { path: `${ConfigUrl.appUrl}forgot-password`, component: <ForgetPwd /> },
  { path: `${ConfigUrl.appUrl}register`, component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
