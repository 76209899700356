import React, { useState, useEffect } from "react"
import { Col, Container, Form, Modal, Row } from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { Card } from "reactstrap"

import { Link, useNavigate } from "react-router-dom"
import { CardBody } from "reactstrap"
import Select from "react-select"
import axios from "axios"
import Loader from "pages/Loader"
import swal from "sweetalert"
import { getEncryptedData, getDecryptedData, Config } from "pages/Config"

const Profile = props => {
  // states for fields
  const [fullname, setFullname] = useState("")
  const [gender, setGender] = useState("")
  const [country, setCountry] = useState("")
  const [state, setState] = useState("")
  const [city, setCity] = useState("")
  const [address, setAddress] = useState("")
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [phone_number, setPhoneNumber] = useState("")

  const [age, setAge] = useState("") // Assuming age is a number
  const [height, setHeight] = useState("") // Assuming height is a number
  const [weight, setWeight] = useState("") // Assuming weight is a number
  const [bmi_number, setBmiNumber] = useState("") // Assuming bmi_number is a number
  const [ideal_bodyweight, setIdealBodyweight] = useState("") // Assuming ideal_bodyweight is a number
  const [job_type, setJobType] = useState("")
  const [working_hours, setWorkingHours] = useState("")
  const [client_goal, setClientGoal] = useState("")
  const [physical_activity, setPhysicalActivity] = useState(null)
  const [activity, setActivity] = useState("")
  const [supplements, setSupplements] = useState("")
  const [family_history, setFamilyHistory] = useState("")
  const [heighest_weight, setHeighestWeight] = useState("") // Assuming heighest_weight is a number
  const [stomach_issue, setStomachIssue] = useState("")
  const [s_issue, setSIssue] = useState("")
  const [medical_issue, setMedicalIssue] = useState([])
  const [m_issue, setMIssue] = useState("")
  const [tried_losing_weight, setTriedLosingWeight] = useState(null)
  const [tried_activity, setTriedActivity] = useState("")
  const [cheat_meal, setCheatMeal] = useState("")
  // const [meal_description, setMealDescription] = useState('');
  const [food_preference, setFoodPreference] = useState("")
  const [food_prefer_detail, setFoodPreferDetail] = useState("")
  const [bodyFats, setBodyFats] = useState([])
  const [bodyFatsDesc, setBodyFatsDesc] = useState("")
  const [surgery, setSurgery] = useState(null)
  const [surgery_detail, setSurgeryDetail] = useState("")
  const [likes, setLikes] = useState("")
  const [dislikes, setDislikes] = useState("")
  const [allergies, setAllergies] = useState("")
  const [cravings, setCravings] = useState("")
  const [outside_food_detail, setOutsideFoodDetail] = useState("")
  const [smokeAlcohol, setSmokeAlcohol] = useState(null)
  const [addiction_detail, setAddictionDetail] = useState("")
  const [water_intake, setWaterIntake] = useState("") // Assuming water_intake is a number
  const [sleep_cycle, setSleepCycle] = useState("")
  const [oil, setOil] = useState("")
  const [salt, setSalt] = useState("")
  const [flour, setFlour] = useState("")
  const [stress_level, setStressLevel] = useState("")
  const [sleeps_at, setSleepsAt] = useState("")
  const [wake_up, setWakeUp] = useState("")
  const [breakfast, setBreakfast] = useState({ foods: "", time: "" })
  const [mid_morning, setMidMorning] = useState({ foods: "", time: "" })
  const [lunch, setLunch] = useState({ foods: "", time: "" })
  const [evening_snack, setEveningSnack] = useState({ foods: "", time: "" })
  const [dinner, setDinner] = useState({ foods: "", time: "" })
  const [post_dinner, setPostDinner] = useState({ foods: "", time: "" })
  const [startdate_of_package, setStartdateOfPackage] = useState("")
  const [packageName, setPackageName] = useState("")
  const [menstrual_cycle, setMensCycle] = useState("")

  // other states

  const [countries, setCountries] = useState([])
  const [states, setStates] = useState([])
  const [cities, setCities] = useState([])

  // form field states for dependent fields

  // reset password modal
  const [passwordModal, setPasswordModal] = useState(false)
  const [resetPwd, setResetPwd] = useState("")
  const [confirmPwd, setConfirmPwd] = useState("")
  const [showPwd, setShowPwd] = useState(false)

  const [loading, setLoading] = useState(false)

  const [fixButtons, setFixButtons] = useState(false)
  document.addEventListener("scroll", () => {
    if (window.scrollY > 160) {
      setFixButtons(true)
    } else {
      setFixButtons(false)
    }
  })

  const navigate = useNavigate()

  const colourStyles = {
    menuList: styles => ({
      ...styles,
      background: "white",
    }),
    menu: base => ({
      ...base,
      zIndex: 50,
    }),
  }

  const weekDays = [
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
  ]

  const medicalIssues = [
    { label: "Issue 1", value: "Issue 1" },
    { label: "Issue 2", value: "Issue 2" },
    { label: "Other", value: "Other" },
  ]

  const bodyFatsOptions = [
    { label: "Option 1", value: "Option 1" },
    { label: "Option 2", value: "Option 2" },
    { label: "Other", value: "Other" },
  ]

  const [allPackages, setAllPackages] = useState([])

  useEffect(() => {
    // getting Packages details
    const formData = new FormData()
    formData.append("added_by", localStorage.getItem("adminId"))
    axios
      .post(`${Config.apiUrl}/getpackages`, formData)
      .then(res => {
        setAllPackages(res.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  // get all countries name and all client data
  useEffect(() => {
    // getting existing client data
    const getClientData = () => {
      setLoading(true)
      axios
        .get(`${Config.apiUrl}/view_client/${props.client_id}`, {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        })
        .then(response => {
          // console.log("client", response)
          // setLikes()

          setFullname(getDecryptedData(response.data.data[0].fullname))
          setGender(getDecryptedData(response.data.data[0].gender))
          setMensCycle(getDecryptedData(response.data.data[0].menstrual_cycle))

          setEmail(response.data.data[0].email)
          // setPassword(response.data.data[0].password)
          setPhoneNumber(getDecryptedData(response.data.data[0].phone_number))

          setCountry(response.data.data[0].country)
          // getting all state list
          getStatesList(response.data.data[0].country)

          setState(response.data.data[0].state)

          getCitiesList(response.data.data[0].state)

          setCity(response.data.data[0].city)
          setAddress(getDecryptedData(response.data.data[0].address))
          setAge(getDecryptedData(response.data.data[0].age))

          if (
            response.data.data[0].height != "null" &&
            response.data.data[0].height != "undefined"
          )
            setHeight(getDecryptedData(response.data.data[0].height))

          if (
            response.data.data[0].weight != "null" &&
            response.data.data[0].weight != "undefined"
          )
            setWeight(getDecryptedData(response.data.data[0].weight))

          if (
            response.data.data[0].bmi_number != "null" &&
            response.data.data[0].bmi_number != "undefined"
          )
            setBmiNumber(getDecryptedData(response.data.data[0].bmi_number))

          setIdealBodyweight(
            getDecryptedData(response.data.data[0].ideal_bodyweight)
          )
          setJobType(getDecryptedData(response.data.data[0].job_type))

          if (
            response.data.data[0].working_hours != "null" &&
            response.data.data[0].working_hours != "undefined"
          )
            setWorkingHours(
              getDecryptedData(response.data.data[0].working_hours)
            )
          setClientGoal(getDecryptedData(response.data.data[0].client_goal))

          setPhysicalActivity(
            getDecryptedData(response.data.data[0].physical_activity)
          )
          if (
            response.data.data[0].activity != "null" &&
            response.data.data[0].activity != "undefined"
          )
            setActivity(getDecryptedData(response.data.data[0].activity))

          if (
            response.data.data[0].supplements != "null" &&
            response.data.data[0].supplements != "undefined"
          )
            setSupplements(getDecryptedData(response.data.data[0].supplements))

          if (
            response.data.data[0].family_history != "null" &&
            response.data.data[0].family_history != "undefined"
          )
            setFamilyHistory(
              getDecryptedData(response.data.data[0].family_history)
            )

          if (
            response.data.data[0].heighest_weight != "null" &&
            response.data.data[0].heighest_weight != "undefined"
          )
            setHeighestWeight(
              getDecryptedData(response.data.data[0].heighest_weight)
            )

          setStomachIssue(getDecryptedData(response.data.data[0].stomach_issue))
          if (
            response.data.data[0].s_issue != "null" &&
            response.data.data[0].s_issue != "undefined"
          )
            setSIssue(getDecryptedData(response.data.data[0].s_issue))

          if (response.data.data[0].medical_issue != null)
            setMedicalIssue(
              JSON.parse(getDecryptedData(response.data.data[0].medical_issue))
            )
          if (
            response.data.data[0].m_issue != "null" &&
            response.data.data[0].m_issue != "undefined"
          )
            setMIssue(getDecryptedData(response.data.data[0].m_issue))

          setTriedLosingWeight(
            getDecryptedData(response.data.data[0].tried_losing_weight)
          )
          if (
            response.data.data[0].tried_activity != "null" &&
            response.data.data[0].tried_activity != "undefined"
          )
            setTriedActivity(
              getDecryptedData(response.data.data[0].tried_activity)
            )
          // =======================

          if (response.data.data[0].body_fat_distribution != null)
            setBodyFats(
              JSON.parse(
                getDecryptedData(response.data.data[0].body_fat_distribution)
              )
            )

          if (
            response.data.data[0].describe != "null" &&
            response.data.data[0].describe != "undefined"
          )
            setBodyFatsDesc(getDecryptedData(response.data.data[0].describe))

          setCheatMeal(
            JSON.parse(getDecryptedData(response.data.data[0].cheat_meal))
          )

          setFoodPreference(
            getDecryptedData(response.data.data[0].food_preference)
          )
          if (
            response.data.data[0].food_detail != "null" &&
            response.data.data[0].food_detail != "undefined"
          )
            setFoodPreferDetail(
              getDecryptedData(response.data.data[0].food_detail)
            )

          setSurgery(getDecryptedData(response.data.data[0].surgery))
          if (
            response.data.data[0].surgery_detail != "null" &&
            response.data.data[0].surgery_detail != "undefined"
          )
            setSurgeryDetail(
              getDecryptedData(response.data.data[0].surgery_detail)
            )

          if (
            response.data.data[0].likes != "null" &&
            response.data.data[0].likes != "undefined"
          )
            setLikes(getDecryptedData(response.data.data[0].likes))

          if (
            response.data.data[0].dislikes != "null" &&
            response.data.data[0].dislikes != "undefined"
          )
            setDislikes(getDecryptedData(response.data.data[0].dislikes))

          if (
            response.data.data[0].allergies != "null" &&
            response.data.data[0].allergies != "undefined"
          )
            setAllergies(getDecryptedData(response.data.data[0].allergies))

          if (
            response.data.data[0].cravings != "null" &&
            response.data.data[0].cravings != "undefined"
          )
            setCravings(getDecryptedData(response.data.data[0].cravings))

          if (
            response.data.data[0].outside_food_detail != "null" &&
            response.data.data[0].outside_food_detail != "undefined"
          )
            setOutsideFoodDetail(
              getDecryptedData(response.data.data[0].outside_food_detail)
            )

          setSmokeAlcohol(getDecryptedData(response.data.data[0].addiction))

          if (
            response.data.data[0].addiction_detail != "null" &&
            response.data.data[0].addiction_detail != "undefined"
          )
            setAddictionDetail(
              getDecryptedData(response.data.data[0].addiction_detail)
            )

          if (
            response.data.data[0].water_intake != "null" &&
            response.data.data[0].water_intake != "undefined"
          )
            setWaterIntake(getDecryptedData(response.data.data[0].water_intake))

          setSleepCycle(getDecryptedData(response.data.data[0].sleep_cycle))
          if (
            response.data.data[0].oil != "null" &&
            response.data.data[0].oil != "undefined"
          )
            setOil(getDecryptedData(response.data.data[0].oil))

          if (
            response.data.data[0].salt != "null" &&
            response.data.data[0].salt != "undefined"
          )
            setSalt(getDecryptedData(response.data.data[0].salt))

          if (
            response.data.data[0].flour != "null" &&
            response.data.data[0].flour != "undefined"
          )
            setFlour(getDecryptedData(response.data.data[0].flour))

          setStressLevel(getDecryptedData(response.data.data[0].stress_level))

          setSleepsAt(getDecryptedData(response.data.data[0].sleeps_at))
          setWakeUp(getDecryptedData(response.data.data[0].wake_up))

          if (response.data.data[0].breakfast != null)
            setBreakfast(
              JSON.parse(getDecryptedData(response.data.data[0].breakfast))
            )

          if (response.data.data[0].mid_morning != null)
            setMidMorning(
              JSON.parse(getDecryptedData(response.data.data[0].mid_morning))
            )

          if (response.data.data[0].lunch != null)
            setLunch(JSON.parse(getDecryptedData(response.data.data[0].lunch)))

          if (response.data.data[0].evening_snack != null)
            setEveningSnack(
              JSON.parse(getDecryptedData(response.data.data[0].evening_snack))
            )

          if (response.data.data[0].dinner != null)
            setDinner(
              JSON.parse(getDecryptedData(response.data.data[0].dinner))
            )

          if (response.data.data[0].post_dinner != null)
            setPostDinner(
              JSON.parse(getDecryptedData(response.data.data[0].post_dinner))
            )

          setStartdateOfPackage(response.data.data[0].startdate_of_package)

          setPackageName(response.data.data[0].package)

          setMensCycle(getDecryptedData(response.data.data[0].menstrual_cycle))

          if (
            JSON.parse(getDecryptedData(response.data.data[0].medical_issue))[0].value != "Other"
        ) {
            setMIssue("")
          }

          if (
            JSON.parse(
              getDecryptedData(response.data.data[0].body_fat_distribution)
            )[0].value != "Other"
          ) {
            setBodyFatsDesc("")
          }

          setLoading(false)
        })
        .catch(error => {
          console.log(error)
          setLoading(false)
        })
    }

    getClientData()

    axios
      .get(`${Config.apiUrl}/country`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setCountries(response.data.data)
      })
      .catch(err => console.log(err))
  }, [])

  // get all states name
  const getStatesList = country_id => {
    setCountry(country_id)

    const formData = new FormData()
    formData.append("country_id", country_id)

    axios
      .post(`${Config.apiUrl}/country_state`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setStates(response.data.data)
      })
      .catch(err => console.log(err))
  }

  // get all cities name
  const getCitiesList = state_id => {
    setState(state_id)

    const formData = new FormData()
    formData.append("state_id", state_id)

    axios
      .post(`${Config.apiUrl}/state_city`, formData, {
        headers: {
          Accept: "application/json",
          "Content-Type": "multipart/form-data",
        },
      })
      .then(response => {
        setCities(response.data.data)
      })
      .catch(err => console.log(err))
  }

  // final form submit
  const handleFormSubmit = async e => {
    e.preventDefault()

    try {
      // =================================

      const formData = new FormData()

      formData.append("fullname", getEncryptedData(fullname))
      formData.append("gender", getEncryptedData(gender))
      formData.append("email", email)
      // formData.append("password", password);
      formData.append("phone_number", getEncryptedData(phone_number))
      formData.append("country", country)
      formData.append("state", state)
      formData.append("city", city)
      formData.append("address", getEncryptedData(address))
      formData.append("age", getEncryptedData(age))
      formData.append("height", getEncryptedData(height))
      formData.append("weight", getEncryptedData(weight))
      formData.append("bmi_number", getEncryptedData(bmi_number))
      formData.append("ideal_bodyweight", getEncryptedData(ideal_bodyweight))
      formData.append("job_type", getEncryptedData(job_type))
      formData.append("working_hours", getEncryptedData(working_hours))
      formData.append("client_goal", getEncryptedData(client_goal))
      formData.append("physical_activity", getEncryptedData(physical_activity))
      formData.append("activity", getEncryptedData(activity))
      formData.append("supplements", getEncryptedData(supplements))
      formData.append("family_history", getEncryptedData(family_history))
      formData.append("heighest_weight", getEncryptedData(heighest_weight))
      formData.append("stomach_issue", getEncryptedData(stomach_issue))
      formData.append("s_issue", getEncryptedData(s_issue))
      formData.append(
        "medical_issue",
        getEncryptedData(JSON.stringify(medical_issue))
      )
      formData.append("m_issue", getEncryptedData(m_issue))
      formData.append("tried_losing_weight", tried_losing_weight)
      formData.append("tried_activity", getEncryptedData(tried_activity))

      formData.append(
        "body_fat_distribution",
        getEncryptedData(JSON.stringify(bodyFats))
      )
      formData.append("describe", getEncryptedData(bodyFatsDesc))

      formData.append(
        "cheat_meal",
        getEncryptedData(JSON.stringify(cheat_meal))
      )
      formData.append("food_preference", getEncryptedData(food_preference))
      formData.append(
        "food_prefer_detail",
        getEncryptedData(food_prefer_detail)
      )
      formData.append("surgery", getEncryptedData(surgery))
      formData.append("surgery_detail", getEncryptedData(surgery_detail))
      formData.append("likes", getEncryptedData(likes))
      formData.append("dislikes", getEncryptedData(dislikes))
      formData.append("allergies", getEncryptedData(allergies))
      formData.append("cravings", getEncryptedData(cravings))
      formData.append(
        "outside_food_detail",
        getEncryptedData(outside_food_detail)
      )
      formData.append("addiction", getEncryptedData(smokeAlcohol))
      formData.append("addiction_detail", getEncryptedData(addiction_detail))
      formData.append("water_intake", getEncryptedData(water_intake))
      formData.append("sleep_cycle", getEncryptedData(sleep_cycle))
      formData.append("oil", getEncryptedData(oil))
      formData.append("salt", getEncryptedData(salt))
      formData.append("flour", getEncryptedData(flour))
      formData.append("stress_level", getEncryptedData(stress_level))
      formData.append("sleeps_at", getEncryptedData(sleeps_at))
      formData.append("wake_up", getEncryptedData(wake_up))
      formData.append("breakfast", getEncryptedData(JSON.stringify(breakfast)))
      formData.append(
        "mid_morning",
        getEncryptedData(JSON.stringify(mid_morning))
      )
      formData.append("lunch", getEncryptedData(JSON.stringify(lunch)))
      formData.append(
        "evening_snack",
        getEncryptedData(JSON.stringify(evening_snack))
      )
      formData.append("dinner", getEncryptedData(JSON.stringify(dinner)))
      formData.append(
        "post_dinner",
        getEncryptedData(JSON.stringify(post_dinner))
      )
      formData.append("startdate_of_package", startdate_of_package)
      formData.append("package", packageName)
      formData.append("menstrual_cycle", getEncryptedData(menstrual_cycle))
      formData.append("client_id", props.client_id)
      formData.append("added_by", localStorage.getItem("adminId"))

      const response = await axios.post(
        `${Config.apiUrl}/edit_client`,
        formData,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      if (response.data.data == "email already in use") {
        swal("Email already in use", {
          icon: "warning",
        })
        return
      }
      setLoading(false)

      // if(response.data.dat)

      swal("Client details updated successfully", {
        icon: "success",
      })
    } catch (error) {
      console.log(error)
      setLoading(false)
      swal("Something went wrong", "", "warning")
    }
  }

  const handleResetPassword = async e => {
    e.preventDefault()
    try {
      if (resetPwd === confirmPwd) {
        const formData = new FormData()
        formData.append("client_id", props.client_id)
        formData.append("password", resetPwd)

        const response = await axios.post(
          `${Config.apiUrl}/reset_password`,
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          }
        )

        if (response.data.message == "password reset") {
          setPasswordModal(false)
          setResetPwd("")
          setConfirmPwd("")
          swal("", "Password Reset Successfully", "success")
        }
      } else {
        swal("Confirm Pasword is not Correct")
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <React.Fragment>
      {loading && <Loader />}
      {/* <div className="page-content"> */}
      {/* <Container fluid> */}
      {/* Fixed Buttons */}
      <div
        className={`flex-column fixed-buttons ${
          fixButtons ? "d-flex" : "d-none"
        }`}
      >
        <button
          onClick={handleFormSubmit}
          className="btn btn-purple border-radius fix-btn"
        >
          <i className="bx bx-send fs-5 me-2"></i>
          <span>Submit</span>
        </button>
        <a
          onClick={() => navigate(-1)}
          className="btn btn-dark border-radius fix-btn"
        >
          <i className="fas fa-arrow-left me-2 icon-center"></i>
          <span>Back</span>
        </a>
      </div>
      <Row className="mx-0">
        <form action="" onSubmit={handleFormSubmit} className="px-0">
          <Card>
            <CardBody>
              <Row className="py-2">
                <div className="col-md-6">
                  <h4 className="fw-bold text-purple">Personal Information </h4>
                </div>
                <div className="col-md-6 mb-4 text-end">
                  <button
                    type="button"
                    onClick={() => navigate(-1)}
                    className="btn btn-dark border-radius me-3"
                  >
                    <i className="fas fa-arrow-left me-3"></i>
                    Back
                  </button>
                  <button
                    type="submit"
                    className="btn btn-green btn-purple border-radius"
                  >
                    {" "}
                    <i className="bx bx-send me-1"></i> Submit{" "}
                  </button>
                </div>

                <div className="col-12">
                  <Row className="justify-content-between">
                    <div className="col-12">
                      <Row>
                        <div className="col-md-4">
                          <label htmlFor="" className="fs-5">
                            Full Name <span className="text-danger">*</span>{" "}
                          </label>
                          <input
                            type="text"
                            value={fullname}
                            onChange={e => setFullname(e.target.value)}
                            placeholder="Enter Full Name"
                            className="form-field"
                            required
                          />
                        </div>

                        <div className="col-md-6" >
                          <Row>
                            <label htmlFor="" className="fs-5">
                              Gender <span className="text-danger">*</span>
                            </label>
                            <Row className="mt-2 pt-1">
                              <div className="col-3">
                                <input
                                  type="radio"
                                  checked={gender === "male"}
                                  onClick={e => setGender(e.target.id)}
                                  name="gender"
                                  id="male"
                                  className="fs-5 form-check-input "
                                />
                                <label htmlFor="male" className="ps-2 pt-1">
                                  Male
                                </label>
                              </div>

                              <div className="col-4">
                                <input
                                  type="radio"
                                  checked={gender === "female"}
                                  onClick={e => setGender(e.target.id)}
                                  name="gender"
                                  id="female"
                                  className="form-check-input fs-5 "
                                />
                                <label htmlFor="female" className="ps-2 pt-1">
                                  Female
                                </label>
                              </div>
                              <div className="col-5">
                                <input
                                  type="radio"
                                  checked={gender === "transgender"}
                                  onClick={e => setGender(e.target.id)}
                                  name="gender"
                                  id="transgender"
                                  className="form-check-input fs-5 "
                                />
                                <label
                                  htmlFor="transgender"
                                  className="ps-2 pt-1"
                                >
                                  Transgender
                                </label>
                              </div>
                            </Row>
                          </Row>
                        </div>

                        {gender === "female" && (
                          <div className="col-md-2 ps-md-0">
                            <label htmlFor="" className="fs-5">
                              Menstrual cycle
                            </label>
                            <input
                              type="text"
                              onChange={e => setMensCycle(e.target.value)}
                              value={menstrual_cycle}
                              placeholder="Enter Menstrual cycle"
                              className="form-field dynamic-field"
                            />
                          </div>
                        )}

                        <div className="col-md-4 mt-4">
                          <label htmlFor="" className="fs-5">
                            Email <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            placeholder="Enter Email"
                            className="form-field"
                            required
                          />
                        </div>

                        <div className="col-md-4  mt-4">
                          <label htmlFor="" className="fs-5">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={phone_number}
                            onChange={e => setPhoneNumber(e.target.value)}
                            placeholder="Enter Phone Number"
                            className="form-field"
                            required
                          />
                        </div>

                        <div className="col-md-4 mt-4 text-end">
                          <label htmlFor="" className="fs-5">
                            Need to change your Password?
                          </label>
                          <button
                            type="button"
                            onClick={() => setPasswordModal(true)}
                            className="btn btn-green btn-purple border-radius"
                          >
                            <i className="fas fa-pen me-1"></i> Reset Your
                            Password{" "}
                          </button>
                        </div>

                        <div className="col-md-4 mt-4">
                          <label htmlFor="" className="fs-5">
                            Country <span className="text-danger">*</span>
                          </label>

                          <select
                            onChange={e => getStatesList(e.target.value)}
                            value={country}
                            className="form-field"
                            required
                          >
                            <option value=""> Nothing Selected </option>
                            {countries.map(country => {
                              return (
                                <option
                                  key={country.id}
                                  name={country.name}
                                  value={country.id}
                                >
                                  {country.name}{" "}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="col-md-4 mt-4">
                          <label htmlFor="" className="fs-5">
                            State <span className="text-danger">*</span>
                          </label>

                          <select
                            name="states"
                            value={state}
                            onChange={e => getCitiesList(e.target.value)}
                            className="form-field"
                            required
                          >
                            <option value=""> Nothing Selected </option>
                            {states.map(state => {
                              return (
                                <option key={state.id} value={state.id}>
                                  {state.name}{" "}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="col-md-4 mt-4">
                          <label htmlFor="" className="fs-5">
                            City <span className="text-danger">*</span>
                          </label>
                          <select
                            name="cities"
                            value={city}
                            onChange={e => setCity(e.target.value)}
                            className="form-field"
                            required
                          >
                            <option value=""> Nothing Selected </option>
                            {cities.map(city => {
                              return (
                                <option key={city.id} value={city.id}>
                                  {city.name}{" "}
                                </option>
                              )
                            })}
                          </select>
                        </div>

                        <div className="col-12 mt-4">
                          <label htmlFor="" className="fs-5">
                            Address <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            value={address}
                            onChange={e => setAddress(e.target.value)}
                            placeholder="Enter Address"
                            className="form-field"
                            required
                          />
                        </div>
                      </Row>
                    </div>

                    <div className="col-12 mt-5">
                      <Row>
                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="fs-5">
                            Age
                          </label>
                          <input
                            type="text"
                            value={age}
                            onChange={e => setAge(e.target.value)}
                            placeholder="Enter Age"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="fs-5">
                            Height
                          </label>
                          <input
                            type="text"
                            value={height}
                            onChange={e => setHeight(e.target.value)}
                            placeholder="Enter Height"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-2 mt-4">
                          <label htmlFor="" className="fs-5">
                            Weight
                          </label>
                          <input
                            type="text"
                            value={weight}
                            onChange={e => setWeight(e.target.value)}
                            placeholder="kgs / lbs"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="fs-5">
                            BMI Number
                          </label>
                          <input
                            type="text"
                            value={bmi_number}
                            onChange={e => setBmiNumber(e.target.value)}
                            placeholder="Enter BMI Number"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="fs-5">
                            Ideal Body Weight
                          </label>
                          <input
                            type="text"
                            value={ideal_bodyweight}
                            onChange={e => setIdealBodyweight(e.target.value)}
                            placeholder="Enter Ideal Body Weight"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="fs-5">
                            Type of job
                          </label>
                          <select
                            className="form-field"
                            value={job_type}
                            onChange={e => setJobType(e.target.value)}
                          >
                            <option value="">Select Job type</option>
                            <option value="Desk job">Desk job</option>
                            <option value="Field work">Field work</option>
                          </select>
                        </div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="fs-5">
                            Working hours
                          </label>
                          <input
                            type="text"
                            value={working_hours}
                            onChange={e => setWorkingHours(e.target.value)}
                            placeholder="Enter Working hours"
                            className="form-field"
                          />
                        </div>

                        <div className="col-md-3 mt-4"></div>

                        <div className="col-md-3 mt-4">
                          <label htmlFor="" className="fs-5 text-danger">
                            Client Goal <span className="text-danger">*</span>
                          </label>
                          <select
                            className="form-field border border-danger"
                            value={client_goal}
                            onChange={e => setClientGoal(e.target.value)}
                          >
                            <option value="">-- Select Your Goal --</option>
                            <option value="weight loss">Weight Loss</option>
                            <option value="weight gain">Weight Gain</option>
                            <option value="lifestyle">Lifestyle</option>
                          </select>
                        </div>

                        <div className={`col-md-3 mt-4`}>
                          <label htmlFor="" className="fs-5">
                            Physical Activity
                          </label>
                          <select
                            className="form-field"
                            value={physical_activity}
                            onChange={e => setPhysicalActivity(e.target.value)}
                          >
                            <option value="">Select Choice</option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        <div className="col-md-9 mt-4">
                          <label htmlFor="" className="fs-5">
                            Activity
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Activity"
                            value={activity}
                            onChange={e => setActivity(e.target.value)}
                            className={`form-field ${
                              physical_activity === "Yes" && "dynamic-field"
                            } `}
                            disabled={
                              physical_activity === "Yes" ? false : true
                            }
                          />
                        </div>
                      </Row>
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="py-2">
                <h4 className="fw-bold text-purple">
                  Health Related Information{" "}
                </h4>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Supplements/Medicines
                  </label>
                  <input
                    type="text"
                    value={supplements}
                    onChange={e => setSupplements(e.target.value)}
                    placeholder="Enter Supplements/Medicines"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Family history
                  </label>
                  <input
                    type="text"
                    value={family_history}
                    onChange={e => setFamilyHistory(e.target.value)}
                    placeholder="Enter Family history"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Is this highest weight?
                  </label>
                  <input
                    type="text"
                    value={heighest_weight}
                    onChange={e => setHeighestWeight(e.target.value)}
                    placeholder="Enter Answer"
                    className="form-field"
                  />
                </div>

                <div className="col-12 mt-4">
                  <Row>
                    <div className={`col-md-4`}>
                      <label htmlFor="" className="fs-5">
                        Stomach issue
                      </label>
                      <select
                        className="form-field"
                        value={stomach_issue}
                        onChange={e => setStomachIssue(e.target.value)}
                      >
                        <option value="">Select Issue</option>
                        <option value="Yes">Yes</option>
                        <option value="No">No</option>
                      </select>
                    </div>

                    <div className="col-md-8">
                      <label htmlFor="" className="fs-5">
                        Issue
                      </label>
                      <input
                        type="text"
                        disabled={stomach_issue === "Yes" ? false : true}
                        placeholder="Enter Issue"
                        value={s_issue}
                        onChange={e => setSIssue(e.target.value)}
                        className={`form-field ${
                          stomach_issue === "Yes" && "dynamic-field"
                        } `}
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Medical issue
                  </label>
                  <Select
                    placeholder="Select Issue"
                    className="basic-multi-select"
                    classNamePrefix="border-radius select"
                    styles={colourStyles}
                    isMulti
                    value={medical_issue}
                    onChange={e => setMedicalIssue(e)}
                    options={medicalIssues}
                  />
                </div>

                <div className="col-md-8 mt-4">
                  <label htmlFor="" className="fs-5">
                    Issue
                  </label>
                  <input
                    type="text"
                    value={m_issue}
                    onChange={e => setMIssue(e.target.value)}
                    placeholder="Enter Issue"
                    className={`form-field ${
                      medical_issue.length &&
                      medical_issue[0]?.value === "Other" &&
                      "dynamic-field"
                    } `}
                    disabled={
                      medical_issue.length &&
                      medical_issue[0]?.value === "Other"
                        ? false
                        : true
                    }
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Tried losing weight
                  </label>
                  <select
                    className="form-field"
                    value={tried_losing_weight}
                    onChange={e => setTriedLosingWeight(e.target.value)}
                  >
                    <option value="">Select Choice</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-8 mt-4">
                  <label htmlFor="" className="fs-5">
                    What you have tried?
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Description"
                    onChange={e => setTriedActivity(e.target.value)}
                    value={tried_activity}
                    disabled={tried_losing_weight === "Yes" ? false : true}
                    className={`form-field ${
                      tried_losing_weight === "Yes" && "dynamic-field"
                    } `}
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Body fat distribution
                  </label>
                  <Select
                    placeholder="Select Cheat Meal"
                    className="basic-multi-select"
                    classNamePrefix="border-radius select"
                    styles={colourStyles}
                    isMulti
                    value={bodyFats}
                    onChange={e => setBodyFats(e)}
                    options={bodyFatsOptions}
                  />
                </div>

                <div className="col-md-8 mt-4">
                  <label htmlFor="" className="fs-5">
                    Describe
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Answer"
                    value={bodyFatsDesc}
                    onChange={e => setBodyFatsDesc(e.target.value)}
                    className={`form-field ${
                      bodyFats.length &&
                      bodyFats[0]?.value === "Other" &&
                      "dynamic-field"
                    } `}
                    disabled={
                      bodyFats.length && bodyFats[0]?.value === "Other"
                        ? false
                        : true
                    }
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Food preference
                  </label>
                  <select
                    className="form-field"
                    value={food_preference}
                    onChange={e => setFoodPreference(e.target.value)}
                  >
                    <option value="">Select Choice</option>
                    <option value="Vegetarian">Vegetarian</option>
                    <option value="Non vegetarian">Non vegetarian</option>
                    <option value="Other">Other</option>
                  </select>
                </div>

                <div className="col-md-8 mt-4">
                  <label htmlFor="" className="fs-5">
                    What food you prefers?
                  </label>
                  <input
                    type="text"
                    value={food_prefer_detail}
                    onChange={e => setFoodPreferDetail(e.target.value)}
                    placeholder="Enter Food Preference"
                    disabled={food_preference !== "" ? false : true}
                    className={`form-field ${
                      food_preference !== "" && "dynamic-field"
                    } `}
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Any Surgery
                  </label>
                  <select
                    className="form-field"
                    value={surgery}
                    onChange={e => setSurgery(e.target.value)}
                  >
                    <option value="">Select Choice</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-8 mt-4">
                  <label htmlFor="" className="fs-5">
                    Surgery
                  </label>
                  <input
                    type="text"
                    placeholder="Enter Surgery"
                    value={surgery_detail}
                    onChange={e => setSurgeryDetail(e.target.value)}
                    disabled={surgery === "Yes" ? false : true}
                    className={`form-field ${
                      surgery === "Yes" && "dynamic-field"
                    }`}
                  />
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="py-2">
                <h4 className="fw-bold text-purple">Food Information </h4>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Likes
                  </label>
                  <input
                    type="text"
                    value={likes}
                    onChange={e => setLikes(e.target.value)}
                    placeholder="Enter Likes"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Dislikes
                  </label>
                  <input
                    type="text"
                    value={dislikes}
                    onChange={e => setDislikes(e.target.value)}
                    placeholder="Enter Dislikes"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Allergies
                  </label>
                  <input
                    type="text"
                    value={allergies}
                    onChange={e => setAllergies(e.target.value)}
                    placeholder="Enter Allergies"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Cravings
                  </label>
                  <input
                    type="text"
                    value={cravings}
                    onChange={e => setCravings(e.target.value)}
                    placeholder="Enter Cravings"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Cheat Meal
                  </label>
                  <Select
                    placeholder="Select Cheat Meal"
                    className="basic-multi-select"
                    classNamePrefix="border-radius select"
                    styles={colourStyles}
                    isMulti
                    value={cheat_meal}
                    onChange={e => setCheatMeal(e)}
                    options={weekDays}
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    How often eat outside
                  </label>
                  <input
                    type="text"
                    value={outside_food_detail}
                    onChange={e => setOutsideFoodDetail(e.target.value)}
                    placeholder="Enter Answer"
                    maxLength={7}
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Alcohol/smokes
                  </label>
                  <select
                    className="form-field"
                    value={smokeAlcohol}
                    onChange={e => setSmokeAlcohol(e.target.value)}
                  >
                    <option value="">Select Choice</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Describe
                  </label>
                  <input
                    type="text"
                    placeholder="Enter description"
                    value={addiction_detail}
                    onChange={e => setAddictionDetail(e.target.value)}
                    disabled={smokeAlcohol === "Yes" ? false : true}
                    className={`form-field ${
                      smokeAlcohol === "Yes" && "dynamic-field"
                    }`}
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Water intake
                  </label>
                  <input
                    type="text"
                    value={water_intake}
                    onChange={e => setWaterIntake(e.target.value)}
                    placeholder="Enter as per glass / ltr "
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Sleep cycle
                  </label>
                  <select
                    value={sleep_cycle}
                    onChange={e => setSleepCycle(e.target.value)}
                    className="form-field"
                  >
                    <option value="">Select Choice</option>
                    <option value="sound">sound</option>
                    <option value="disturbed">disturbed </option>
                  </select>
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Oil
                  </label>
                  <input
                    type="text"
                    value={oil}
                    onChange={e => setOil(e.target.value)}
                    placeholder="Enter Oil"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Salt
                  </label>
                  <input
                    type="text"
                    value={salt}
                    onChange={e => setSalt(e.target.value)}
                    placeholder="Enter Salt"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Flour
                  </label>
                  <input
                    type="text"
                    value={flour}
                    onChange={e => setFlour(e.target.value)}
                    placeholder="Enter Flour"
                    className="form-field"
                  />
                </div>

                <div className="col-md-4 mt-4">
                  <label htmlFor="" className="fs-5">
                    Stress level
                  </label>
                  <select
                    value={stress_level}
                    onChange={e => setStressLevel(e.target.value)}
                    className="form-field"
                  >
                    <option value="">Select Choice</option>
                    <option value="Low">Low</option>
                    <option value="Moderate">Moderate </option>
                    <option value="High">High </option>
                    <option value="Very High">Very High </option>
                  </select>
                </div>

                <div className="col-md-4 mt-4">
                  <Row>
                    <div className="col-md-6">
                      <label htmlFor="" className="fs-5">
                        Sleeps at{" "}
                      </label>
                      <input
                        type="time"
                        value={sleeps_at}
                        onChange={e => setSleepsAt(e.target.value)}
                        className="form-field"
                      />
                    </div>
                    <div className="col-md-6">
                      <label htmlFor="" className="fs-5">
                        Wake up
                      </label>
                      <input
                        type="time"
                        value={wake_up}
                        onChange={e => setWakeUp(e.target.value)}
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="py-2">
                <h4 className="fw-bold text-purple">Food Consumption</h4>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Breakfast
                      </label>
                      <input
                        type="time"
                        value={breakfast.time}
                        onChange={e =>
                          setBreakfast({ ...breakfast, time: e.target.value })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={breakfast.foods}
                        onChange={e =>
                          setBreakfast({ ...breakfast, foods: e.target.value })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Mid morning
                      </label>
                      <input
                        type="time"
                        value={mid_morning.time}
                        onChange={e =>
                          setMidMorning({
                            ...mid_morning,
                            time: e.target.value,
                          })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={mid_morning.foods}
                        onChange={e =>
                          setMidMorning({
                            ...mid_morning,
                            foods: e.target.value,
                          })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Lunch
                      </label>
                      <input
                        type="time"
                        value={lunch.time}
                        onChange={e =>
                          setLunch({ ...lunch, time: e.target.value })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={lunch.foods}
                        onChange={e =>
                          setLunch({ ...lunch, foods: e.target.value })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Evening snack
                      </label>
                      <input
                        type="time"
                        value={evening_snack.time}
                        onChange={e =>
                          setEveningSnack({
                            ...evening_snack,
                            time: e.target.value,
                          })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={evening_snack.foods}
                        onChange={e =>
                          setEveningSnack({
                            ...evening_snack,
                            foods: e.target.value,
                          })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Dinner
                      </label>
                      <input
                        type="time"
                        value={dinner.time}
                        onChange={e =>
                          setDinner({ ...dinner, time: e.target.value })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={dinner.foods}
                        onChange={e =>
                          setDinner({ ...dinner, foods: e.target.value })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>

                <div className="col-md-12 mt-4">
                  <Row className="align-items-end">
                    <div className="col-md-3">
                      <label htmlFor="" className="fs-5">
                        Post dinner
                      </label>
                      <input
                        type="time"
                        value={post_dinner.time}
                        onChange={e =>
                          setPostDinner({
                            ...post_dinner,
                            time: e.target.value,
                          })
                        }
                        className="form-field"
                      />
                    </div>

                    <div className="col-md-9">
                      <input
                        type="text"
                        value={post_dinner.foods}
                        onChange={e =>
                          setPostDinner({
                            ...post_dinner,
                            foods: e.target.value,
                          })
                        }
                        placeholder="Enter Food Items"
                        className="form-field"
                      />
                    </div>
                  </Row>
                </div>
              </Row>
            </CardBody>
          </Card>

          <Card>
            <CardBody>
              <Row className="py-2">
                <div className="col-md-4 mt-md-0 mt-4">
                  <label htmlFor="" className="fs-5">
                    Start Date of Package <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    value={startdate_of_package}
                    onChange={e => setStartdateOfPackage(e.target.value)}
                    className="form-field"
                    required
                  />
                </div>

                <div className="col-md-4 mt-md-0 mt-4">
                  <label htmlFor="" className="fs-5">
                    Packages <span className="text-danger">*</span>
                  </label>
                  <select
                    value={packageName}
                    onChange={e => setPackageName(e.target.value)}
                    className="form-field"
                    required
                  >
                    <option value=""> Select Package</option>
                    {allPackages?.map(pack => {
                      return (
                        <option key={pack.id} value={pack.id}>
                          {" "}
                          {getDecryptedData(pack.package_name)}{" "}
                        </option>
                      )
                    })}
                  </select>
                </div>
              </Row>
            </CardBody>
          </Card>
        </form>
      </Row>

      {/* </Container> */}
      {/* </div> */}

      {/* Follow Up Modal */}
      <Modal
        isOpen={passwordModal}
        size="sm"
        className="bd-custom-md-3"
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              setPasswordModal(false)
            }}
            className="btn-modal-close"
          >
            <span aria-hidden="true" className="btn-modal-span">
              &times;
            </span>
          </button>
        </div>
        <div className="modal-body">
          <Form className="row" onSubmit={handleResetPassword}>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Password</label>
              <input
                type={showPwd ? "text" : "password"}
                value={resetPwd}
                onChange={e => setResetPwd(e.target.value)}
                className="form-control border-radius"
                placeholder="Enter New Password"
                required
              />
            </div>
            <div className="mb-3 col-12">
              <label htmlFor="formrow-firstname-Input">Confirm Password</label>
              <input
                type={showPwd ? "text" : "password"}
                value={confirmPwd}
                onChange={e => setConfirmPwd(e.target.value)}
                className="form-control border-radius"
                placeholder="Enter Confirm Password"
                required
              />
            </div>
            <div>
              <input
                type="checkbox"
                id="pwd"
                onChange={e => setShowPwd(e.target.checked)}
              />{" "}
              <label htmlFor="pwd" className="ps-1">
                Show Password
              </label>
            </div>
            <div className="mt-4 text-center">
              <button className="border-radius btn btn-green btn-purple">
                <i className="bx bx-send me-1"></i>
                Reset Now
              </button>
            </div>
          </Form>
        </div>
      </Modal>
      {/* -------------- */}
    </React.Fragment>
  )
}

export default Profile
